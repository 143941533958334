/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Collapse, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Result, Row, Select, Space, Spin, Tabs, Typography, notification } from 'antd';
import {
  CloseOutlined,
  PlusOutlined,
  CheckOutlined,
  ArrowLeftOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined
} from '@ant-design/icons';
import { DocumentsService, IFieldsTemplate, ITemplate, IUpload, IUploadResp, TemplatesService } from '../../shared/services/api';
import { Page, Document, pdfjs } from 'react-pdf';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import dayjs from 'dayjs';
import { RcFile } from 'antd/es/upload';
import { InputMaskCNPJ, InputMaskCPF, InputMaskPhone } from '../../shared/components';
import { usePermission } from '../../shared/contexts/PermissionContext';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

const A4_WIDTH = 595; // Largura padrão do papel A4 em pontos
const A4_HEIGHT = 842; // Altura padrão do papel A4 em pontos

export const CreateDocument = () => {
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const { id = 'id' } = useParams<'id'>();

  const [form] = Form.useForm();
  const [formFields] = Form.useForm();
  const [title, setTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [titleDocument, setTitleDocument] = useState<string>('');
  const [template, setTemplate] = useState<ITemplate>({} as ITemplate);
  const [fields, setFields] = useState<IFieldsTemplate[]>([]);
  const [updateFields, setUpdateFields] = useState<IFieldsTemplate[]>([]);
  const [pdfLink, setPdfLink] = useState<string>('');
  const [keyFile, setKeyFile] = useState<string>('');

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [pages, setPages] = useState(1);
  const [pdfDataUri, setPdfDataUri] = useState<string>('');
  const [participants, setParticipants] = useState<any[]>([]);

  const [tab, setNextTab] = useState(1);
  const [formData, setFormData] = useState<any>({});


  //#region ######### Initialize
  useEffect(() => {
    async function getTemplate(id: string) {
      if (id !== 'novo-modelo') {
        setIsLoading(true);
        await TemplatesService.getById(Number(id))
          .then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              notification.error({
                message: result.message
              });
              goToModelos();
            } else {
              setTemplate(result);
              setTitle(result.description);
              if (result.participants) {
                handleAddSignaturesMassive(result.participants);
              }
              if (result.fields) {
                setFields(result.fields);
              }

              setPdfLink(`https://engmaxsign.com.br/api/downloads/${result.key}?fileType=1`);
            }
          });
      }
    }

    getTemplate(id);
  }, [id]);

  useEffect(() => {
    if (pdfLink) {
      const obterArrayBuffer = async () => {
        try {
          const response = await fetch(pdfLink);
          const pdfBytes = await response.arrayBuffer();

          const pdfDoc = await PDFDocument.load(pdfBytes);
          const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
          const pages = pdfDoc.getPages();
          const textSize = 12;

          if (updateFields) {
            for (let x = 0; x < updateFields.length; x++) {
              const element = updateFields[x];
              const pageIndex = pages[element.pageNumber - 1];
              const pageWidth = pageIndex.getWidth();
              const pageHeight = pageIndex.getHeight();
              const ajust = 850 - pageHeight + 15;
              pageIndex.drawText(`${element.value ? element.value : ''}`, {
                x: element.positionX - element.boundsX - 10,
                y: element.boundsY - element.positionY - ajust,
                size: textSize,
                font: helveticaFont,
                lineHeight: 15,
                color: rgb(0, 0, 0),
              });
            }
          }

          setPdfDataUri(await pdfDoc.saveAsBase64({ dataUri: true }));

        } catch (error) {
          console.error('Erro ao obter o ArrayBuffer do PDF:', error);
        }
      };

      obterArrayBuffer();
    }
  }, [pdfLink, updateFields]);

  //#region ######### Actions
  async function handleSendDocument() {
    await DocumentsService
      .create({
        key: keyFile,
        description: titleDocument,
        deadline: undefined,
        participants: participants,
        templateId: template.id ?? undefined
      })
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: 'Erro',
            description: result.message
          });
        } else {
          notification.success({
            message: 'Sucesso',
            description: 'Documento enviado com sucesso!'
          });
          navigate('/documentos');
        }
      });
  }

  async function formSignatureValid(values: any) {
    if (titleDocument === '') {
      Modal.warning({
        title: 'Atenção',
        content: 'Informe o titulo do documento.'
      });
      return;
    }

    // setParticipants(values.signatories);
    setFormData(values);

    const mergedSignatories: any[] = values.signatories.map((signatory: any) => {
      const filteredFields: any[] = fields.filter(field => field.participantId === signatory.signingPriority);

      return {
        ...signatory,
        fields: filteredFields
      };
    });
    setParticipants(mergedSignatories);

    const file = base64ToFile(pdfDataUri);

    await uploadFile(file as RcFile);

    setNextTab(3);
  }

  async function completeDocument() {
    const file = base64ToFile(pdfDataUri);

    await uploadFile(file as RcFile);

    setNextTab(3);
  }

  const base64ToFile = (base64: string) => {
    // Separa o cabeçalho da string base64 (data:image/png;base64,)
    const base64ContentArray = base64.split(',');
    const contentType = base64ContentArray[0].split(':')[1]; // Obtém o tipo de conteúdo (ex: image/png)
    const byteCharacters = atob(base64ContentArray[1]); // Converte a string base64 em caracteres byte

    // Converte os caracteres byte em uma matriz de bytes
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    // Cria um Blob a partir das matrizes de bytes e define o tipo de conteúdo
    const pdfBlob = new Blob(byteArrays, { type: contentType });
    const pdfFile = new File([pdfBlob], `${titleDocument}.pdf`, { type: 'application/pdf', lastModified: Date.now() });
    return pdfFile;
  };

  async function uploadFile(file: RcFile) {
    setIsLoading(true);
    await DocumentsService
      .uploads({ fileName: `${titleDocument}.pdf`, fileType: 0 } as IUpload)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setKeyFile(result.key);
          uploadAzure(result.url, result.key, file);
        }
      });
  }

  async function uploadAzure(url: string, key: string, file: RcFile) {
    const formData = new FormData();

    formData.append('files[]', file);

    await fetch(url, {
      method: 'PUT',
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-blob-content-type': 'application/octet-stream'
      },
      body: formData,
    })
      .then((res) => console.log(res))
      .then(() => {
        notification.info({
          message: 'Arquivo enviado para o servidor, aguardando geração do documento.'
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Falha ao enviar o arquivo para o servidor.'
        });
      });
  }

  const goToModelos = () => {
    navigate('/modelos');
  };

  function changePage(offset: any) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };
  //#endregion ######### Actions

  const handleAddSignaturesMassive = (array: any[]) => {
    form.resetFields();
    const currentSignatories: any[] = [];

    const newSignatories = array.map(item => {
      return {
        name: '',
        email: '',
        signingRole: 1,
        isSigner: true,
        canRefuse: false
      };
    });

    const updatedSignatories = [
      ...currentSignatories,
      ...newSignatories.map((participant, index) => ({
        ...participant,
        signingPriority: currentSignatories.length + index + 1,
      })),
    ];

    form.setFieldsValue({ signatories: updatedSignatories });
  };

  const handleSaveFormFields = (values: any) => {
    // const novosDadosArray = fields.map((field) => ({
    //   ...field,
    //   value: validateData(field.type, field.key, values)
    //   // value: (field.type === 4 ? formatDate(values[`${field.key}`]) : values[`${field.key}`])
    // }));

    // setPdfLink(`https://engmaxsign.com.br/api/downloads/${template.key}?fileType=1`);
    // setUpdateFields(novosDadosArray);

    // Mapear e adicionar os campos personalizados
    const mergedSignatories: any[] = participants.map((signatory: any) => {
      const filteredFields: any[] = fields.filter(field => field.participantId === signatory.signingPriority);

      return {
        ...signatory,
        fields: filteredFields
      };
    });
    setParticipants(mergedSignatories);

    setNextTab(3);
  };

  function validateData(type: number, key: string, values: any) {
    // (field.type === 4 ? formatDate(values[`${field.key}`]) : values[`${field.key}`])
    if (type === 4) {
      return formatDate(values[`${key}`]);
    }

    if (type === 8) {
      return `R$ ${values[`${key}`]}`;
    }

    return values[`${key}`];
  }

  function formatDate(dateIn: Date) {
    const date = new Date(dateIn);
    const dateDayjs = dayjs(date);
    return dateDayjs.format('DD/MM/YYYY');
  }

  const getFieldComponent = (type: number) => {
    switch (type) {
      case 1:
        return <Input maxLength={100} />;
      case 2:
        return <Input maxLength={100} />;
      case 3:
        return <Input type="email" />;
      case 4:
        return <DatePicker format='DD/MM/YYYY' />;
      case 5:
        return <InputMaskCPF maxLength={11} />;
      case 6:
        return <InputMaskCNPJ maxLength={14} />;
      case 7:
        return <InputMaskPhone type='tel' maxLength={11} />;
      case 8:
        return <Input type='number' prefix="R$" />;
      default:
        return null;
    }
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <Spin spinning={isLoading}>
      <Row style={{ paddingBottom: '16px' }}>
        <Col span={4}>
          <Button type="text" onClick={goToModelos} icon={<ArrowLeftOutlined />}>
            Voltar para modelos
          </Button>
        </Col>
        <Col span={20} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Space>
            <Button
              type="primary"
              onClick={handleSendDocument}
              icon={<CheckOutlined />}
              loading={isLoading}
              disabled={tab !== 3}
            >
              Gerar Documento
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={3}>{title}</Title>
          <Input
            value={titleDocument}
            onChange={(e) => setTitleDocument(e.target.value)}
            required
            placeholder='Titulo do documento...'
            bordered={false}
            className="custom-input"
            style={{
              fontSize: 25,
              fontWeight: 'bold'
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey={'1'} activeKey={tab.toString()}>
            <TabPane tab='Signatários' key="1" disabled={tab === 3}>

              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                form={form}
                name="dynamic_form_complex"
                autoComplete="off"
                onFinish={formSignatureValid}
                initialValues={formData}
              >
                <Row>
                  <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button type="primary" htmlType="submit" >
                      Próximo
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.List
                      name="signatories"
                      rules={[
                        {
                          validator: async (_, signatories) => {
                            if (!signatories || signatories.length < 1) {
                              return Promise.reject(new Error('Informe o(s) signatário(s)'));
                            }
                          },
                        },
                      ]}
                    >
                      {(fields, { remove }, { errors }) => (
                        <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                          {fields.map((field) => (
                            <Card
                              size="small"
                              title={`Signatário ${field.name + 1}`}
                              key={field.key}
                            >
                              <Form.Item
                                label="Ordem"
                                style={{ display: 'none' }}
                                name={[field.name, 'signingPriority']}
                              >
                                <Input type='number' />
                              </Form.Item>

                              <Form.Item
                                label="Name"
                                name={[field.name, 'name']}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{ required: true, message: 'Nome do signatário é um campo obrigatório' }]}
                              >
                                <Input type='text' />
                              </Form.Item>

                              <Form.Item
                                label="E-mail"
                                name={[field.name, 'email']}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={
                                  [
                                    { required: true, message: 'E-mail do signatário é um campo obrigatório' },
                                    { type: 'email', message: 'O e-mail informado no signatário é inválido' }
                                  ]
                                }
                              >
                                <Input type='email' />
                              </Form.Item>
                            </Card>
                          ))}

                          <Form.ErrorList errors={errors} />
                        </div>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </Form>

            </TabPane>
            <TabPane tab='Gerar documento' key="3" disabled={tab === 1}>
              <Result
                title='Tudo certo!'
                subTitle='vamos gerar seu documento.'
                extra={
                  <Button type="primary" key="console" onClick={handleSendDocument}>
                    Gerar documento
                  </Button>
                }
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Spin >
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Typography, notification } from 'antd';
import { IUser, UsersService } from '../../shared/services/api/UsersService';
import { usePermission } from '../../shared/contexts/PermissionContext';

const { Title } = Typography;

export const MyProfile = () => {
  const { user } = usePermission();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<IUser>({} as IUser);

  useEffect(() => {
    // async function getUser(id?: number) {
    //   setIsLoading(true);

    //   await UsersService.get(id!)
    //     .then((result) => {
    //       setIsLoading(false);

    //       if (result instanceof Error) {
    //         notification.error({
    //           message: result.message
    //         });
    //       } else {
    //         setDataTable(result);
    //         form.setFieldsValue(result);
    //       }
    //     });
    // }

    // getUser(user?.id);
    form.setFieldsValue({ users: user });
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  return (
    <>
      <Row>
        <Col span={14}>
          <Title level={3}>Meu perfil</Title >
        </Col>
      </Row>
      <Row style={{ marginBottom: 16 }}>
        <Col span={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>

        </Col>
      </Row>
      <Row>
        <Col span={24}>

          <Form
            {...formItemLayout}
            form={form}
            style={{ maxWidth: 600 }}
            disabled
          >
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                  {fields.map((field) => (

                    <>
                      <Form.Item label="Nome" name={[field.name, 'name']}>
                        <Input />
                      </Form.Item><Form.Item label="E-mail" name={[field.name, 'email']}>
                        <Input />
                      </Form.Item>
                    </>

                  ))}
                </div>
              )}
            </Form.List>

          </Form>

        </Col>
      </Row>
    </>
  );
};

import { Navigate, Route, Routes } from 'react-router-dom';
import {
  SignDocument,
  Login,
  ValidateSignedDocument
} from '../pages';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/assinar/:key' element={<SignDocument />} />
      <Route path='/validar-assinatura-documentos' element={<ValidateSignedDocument />} />

      <Route path="*" element={<Navigate to='/' />} />
    </Routes>
  );
};

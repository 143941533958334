/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Space, Spin, Switch, Table, Tabs, Typography, Upload, UploadFile, UploadProps, notification } from 'antd';
import {
  FormOutlined,
  PlusOutlined,
  CheckOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  CloudUploadOutlined,
  AimOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { DocumentsService, IFieldsTemplate, IParticipantTemplate, ISigningRoles, ITemplate, IUpload, IUploadResp, SigningRolesService, TemplatesService } from '../../shared/services/api';
import { PDFDocument, rgb } from 'pdf-lib';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { RcFile } from 'antd/es/upload';
import uuid from 'react-uuid';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { usePermission } from '../../shared/contexts/PermissionContext';
import { IUser, UsersService } from '../../shared/services/api/UsersService';
import { useDebounce } from '../../shared/hooks';


const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

export const DetailTemplate = () => {
  const { user, hasPermission } = usePermission();

  const navigate = useNavigate();
  const { id = 'novo-modelo' } = useParams<'id'>();
  const [template, setTemplate] = useState<ITemplate>({} as ITemplate);

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isActive, setIsActive] = useState(true);

  // Upload Document
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // Upload Document

  // Signatarios
  const [form] = Form.useForm();
  const [idSignatory, setIdSignatory] = useState(1);
  const [signingRoles, setSigningRoles] = useState<ISigningRoles[]>([]);
  // End Signatarios

  // Campos personalizados
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [fields, setFields] = useState<IFieldsTemplate[]>([]);
  const [participants, setParticipants] = useState<IParticipantTemplate[]>([]);
  const [pages, setPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfDataUri, setPdfDataUri] = useState<string>();
  const [isModalField, setIsModalField] = useState(false);
  // End Campos personalizados

  const [pdfLink, setPdfLink] = useState<string>('');
  const [keyFile, setKeyFile] = useState<string>('');

  const [pageDetails, setPageDetails] = useState({ originalHeight: 0, originalWidth: 0 });
  const documentRef = useRef(null);
  const [text, setText] = useState<string>('');

  // Usuários
  const [dataTable, setDataTable] = useState<IUser[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [isModalUser, setIsModalUser] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const { debounce } = useDebounce();

  //#region ######### Initialize
  useEffect(() => {
    async function getTemplate(id: string) {
      if (id !== 'novo-modelo') {
        setTitle('Editar modelo');
        setIsLoading(true);
        await TemplatesService.getById(Number(id))
          .then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              notification.error({
                message: result.message
              });
              goToModelos();
            } else {
              setTemplate(result);
              setDescription(result.description);

              if (result.fields) {
                setFields(result.fields);
              }
              if (result.participants) {
                setParticipants(result.participants);
              }
              setIsActive(result.isActive);
              setPdfLink(`https://engmaxsign.com.br/api/downloads/${result.key}?fileType=1`);
              setKeyFile(result.key);

              if (result.associatedUsers) {
                setDataTable(result.associatedUsers);
              }
            }
          });
      } else {
        setTitle('Novo modelo');
      }
    }

    getTemplate(id);
  }, []);

  useEffect(() => {
    if (pdfLink) {
      const obterArrayBuffer = async () => {
        try {
          // const existingPdfBytes = await fetch(pdfLink).then(res => res.arrayBuffer());
          const response = await fetch(pdfLink);
          const pdfBytes = await response.arrayBuffer();

          const pdfDoc = await PDFDocument.load(pdfBytes);
          const pages = pdfDoc.getPages();
          setPages(pages.length);

          if (fields) {
            for (let x = 0; x < fields.length; x++) {
              const element = fields[x];
              const pageIndex = pages[element.pageNumber - 1];
              const pageHeight = pageIndex.getHeight();

              // Converta as coordenadas do mouse para as coordenadas do PDF
              const pdfCoordinates = convertToPdfCoordinates(element.positionX, element.positionY, pageHeight);

              pageIndex.drawText(`${element.description}`, {
                x: pdfCoordinates.x,
                y: pdfCoordinates.y, // Invertendo o eixo Y para corresponder ao canvas
                size: 11,
                color: rgb(0, 0, 0),
              });
            }
          }

          setPdfDataUri(await pdfDoc.saveAsBase64({ dataUri: true }));

        } catch (error) {
          console.error('Erro ao obter o ArrayBuffer do PDF:', error);
        }
      };

      obterArrayBuffer();
    }
  }, [pdfLink, fields]);
  //#endregion initialize

  const convertToPdfCoordinates = (x: number, y: number, pageHeight: number) => {
    // A coordenada y do PDF começa no canto inferior esquerdo, portanto precisamos inverter o valor de y
    const invertedY = pageHeight - y;

    // Retorna a coordenada x e a coordenada y invertida
    return { x, y: invertedY };
  };

  //#region ######### Actions
  const goToModelos = () => {
    navigate('/modelos');
  };

  const handleIsActive = (checked: boolean) => {
    setIsActive(checked);
  };

  const handleSaveTemplate = () => {
    if (description === '') {
      notification.warning({
        message: 'Informe descrição do modelo'
      });
      return;
    }

    if (participants.length === 0) {
      notification.warning({
        message: 'Informe o(s) signatário(s).'
      });
      return;
    }

    // if (fields.length === 0) {
    //   notification.warning({
    //     message: 'Informe o(s) campo(s) personalizado(s).'
    //   });
    //   return;
    // }

    if (id !== 'novo-modelo') {
      const fieldsDiferentes = (JSON.stringify(template.fields) !== JSON.stringify(fields));
      const participantsDiferentes = (JSON.stringify(template.participants) !== JSON.stringify(participants));
      const usersIds = dataTable.map(user => user.id);
      setIsLoading(true);
      TemplatesService.updateById(Number(id), {
        description,
        isActive,
        key: keyFile,
        participants: participantsDiferentes ? participants : template.participants,
        fields: fieldsDiferentes ? fields : template.fields,
        associatedUsers: usersIds ? usersIds : []
      })
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
            goToModelos();
          } else {
            notification.success({
              message: 'Modelo atualizado com sucesso!'
            });
          }
        });
    } else {
      if (fileList.length === 0) {
        notification.warning({
          message: 'Selecione o arquivo.'
        });
        return;
      }

      setIsLoading(true);
      TemplatesService.create({
        description,
        isActive,
        key: keyFile,
        participants: participants,
        fields: [],
        associatedUsers: []
      })
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
            goToModelos();
          } else {
            notification.success({
              message: 'Modelo criado com sucesso!'
            });
            //goToModelos();
            navigate(`/modelos/${result.id}`);
          }
        });
    }
  };

  const handleRemove = (id: number) => {
    setIsLoading(true);
    TemplatesService.deleteById(Number(id))
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          notification.info({
            message: 'Modelo deletado com sucesso!'
          });
          goToModelos();
        }

      });
  };

  const handleCreateDocument = (id: number) => {
    navigate(`/modelos/gerar-documento/${id}`);
  };
  //#endregion Actions

  //#region ######### Signatários
  const handleAddSignatory = () => {
    let sPriority = 0;
    if (participants.length > 0) {
      const ultimoObjeto = participants[participants.length - 1];
      sPriority = Number(ultimoObjeto?.signingPriority) + 1;
    } else {
      sPriority = 1;
    }

    setParticipants([...participants, {
      id: sPriority,
      signingPriority: sPriority,
      name: '{{ NOME SIGNATARIO }}',
      email: '{{ EMAIL SIGNATARIO }}',
      isSigner: true,
    }]);

    setIdSignatory(sPriority);
    setPdfLink(`https://engmaxsign.com.br/api/downloads/${keyFile}?fileType=1`);
  };

  const handleRemoveSignatory = () => {
    const updatedData = participants.slice(0, -1);
    setParticipants(updatedData);
  };

  const handleRemoveParticipant = (signingPriority: number) => {
    const updatedData = participants.filter(item => item.signingPriority !== signingPriority);
    setParticipants(updatedData);
  };

  const handleInputChange = (value: string | number | boolean, key: React.Key, dataIndex: keyof IParticipantTemplate) => {
    const newData = participants.map((item) => {
      if (item.signingPriority === key) {
        return {
          ...item,
          [dataIndex]: value,
        };
      }
      return item;
    });
    setParticipants(newData as IParticipantTemplate[]);
  };

  const columns = [
    {
      title: 'Ordem',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Signatário',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      width: '50%',
    },
  ];
  //#endregion Signatarios

  //#region ######### Campos personalizados
  const handleCanvasClick = async (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;

    form.setFieldValue('positionX', x);
    form.setFieldValue('positionY', y);
    form.setFieldValue('pageNumber', pageNumber);

    setIsModalField(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDblClick = (e: any) => {
    form.setFieldValue('positionX', e.screenX);
    form.setFieldValue('positionY', e.screenY);
    form.setFieldValue('pageNumber', pageNumber);

    // setIsModalField(true);
  };

  const handleOkModal = () => {
    const fieldsForm: IFieldsTemplate = form.getFieldsValue();

    if (!fieldsForm.key) {
      fieldsForm.key = uuid();
      setFields([...fields, fieldsForm]);
    } else {

      setFields(fields => {
        return fields.map(field => {
          if (field.key === fieldsForm.key) {
            return { ...field, description: fieldsForm.description };
          }
          return field;
        });
      });
    }

    form.resetFields();
    setIsModalField(false);
  };

  const handleCancelModal = () => {
    form.resetFields();
    setIsModalField(false);
  };

  const handleRemoveField = (key: string) => {
    const updatedData = fields.filter(item => item.key !== key);
    setFields(updatedData);
  };

  const hidrateFormWithValues = async (data: IFieldsTemplate) => {
    form.setFieldsValue(data);
    setIsModalField(true);
  };

  const columnsFields: ColumnsType<IFieldsTemplate> = [
    {
      title: 'Campo personalizado',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Página',
      dataIndex: 'pageNumber',
      key: 'pageNumber',
    },
    {
      title: 'Excluir',
      key: 'actions',
      width: '10%',
      render: (record: IFieldsTemplate) => (
        <Space size="small" style={{ justifyContent: 'center', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<FormOutlined />} onClick={() => hidrateFormWithValues(record)} />
          <Button type="text" danger shape="circle" icon={<DeleteOutlined />} onClick={() => handleRemoveField(record.key)} />
        </Space>
      ),
    }
  ];
  //#endregion campos personalizados

  //#region ######### Documentos
  useEffect(() => {
    if (fileList.length > 0 && !keyFile) {
      setIsLoading(true);
      fileList.forEach((file) => {
        DocumentsService
          .uploads({ fileName: (file as RcFile).name, fileType: 1 } as IUpload)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              notification.error({
                message: result.message
              });
            } else {
              setUpload(result.url);
              setKeyFile(result.key);
            }
          });

        setIsLoading(false);
      });
    }
  }, [fileList]);

  const setUpload = async (url: string) => {
    setIsLoading(true);

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as RcFile);
    });
    // You can use any AJAX library you like
    fetch(url, {
      method: 'PUT',
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-blob-content-type': 'application/octet-stream'
      },
      body: formData,
    })
      .then((res) => console.log(res))
      .then(() => {
        notification.success({
          message: 'Arquivo selecionado com sucesso!',
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Falha ao selecionar o arquivo.'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        notification.warning({
          message: 'Só é possível selecionar arquivos PDF'
        });
        return false;
      }

      if (fileList.length > 0) {
        notification.info({
          message: 'Já existe um arquivo selecionado como modelo'
        });
        return false;
      }
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };
  //#endregion Documentos

  useEffect(() => {
    debounce(() => {
      UsersService.getAll(tableParams.pagination?.current, tableParams.pagination?.pageSize, search, false)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            setUsers(result.data);

            setTableParams({
              ...tableParams,
              pagination: {
                ...tableParams.pagination,
                total: result.total,
              },
            });
          }
        });
    });
  }, [JSON.stringify(tableParams), search]);

  const handleSelectUser = (user: IUser) => {
    if (dataTable.some((aux) => aux.id === user.id)) {
      Modal.warning({
        title: 'Atenção',
        content: 'O usuário ja foi informado.'
      });
      return false;
    } else {
      setDataTable([...dataTable, user]);
      setIsModalUser(false);
      setSearch('');
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
  ) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setUsers([]);
    }
  };

  const handleRemoveAssocietedUser = async (id: number) => {
    const updatedData = dataTable.filter(item => item.id !== id);
    setDataTable(updatedData);
  };

  const columnsAassociatedUsers: ColumnsType<IUser> = [
    {
      title: 'Usuário',
      dataIndex: 'name',
      key: 'name',
    },
    {
      key: 'actions',
      width: '10%',
      render: (record: IUser) => (
        <Popconfirm
          title="Deseja realmente remover este usuario?"
          onConfirm={() => handleRemoveAssocietedUser(record.id)}
          okText="Sim"
          cancelText="Não"
          placement="left"
        >
          <Button type="text" shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    }
  ];

  const columnsUser: ColumnsType<IUser> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Selecionar',
      key: 'actions',
      width: '10%',
      render: (record: IUser) => (
        <Space size="small" style={{ justifyContent: 'center', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<CheckCircleOutlined />} onClick={() => handleSelectUser(record)} />
        </Space>
      ),
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <Row style={{ paddingBottom: '16px' }}>
        <Col span={4}>
          <Button type="text" onClick={goToModelos} icon={<ArrowLeftOutlined />}>
            Voltar para modelos
          </Button>
        </Col>
        <Col span={20} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Space>
            <Button
              type="default"
              onClick={() => handleCreateDocument(Number(id))}
              icon={<FileTextOutlined />}
              loading={isLoading}
              hidden={id === 'novo-modelo' || !isActive || fields.length <= 0}
              disabled={!hasPermission(100)}
            >
              Criar documento
            </Button>
            <Button
              type="primary"
              danger
              // onClick={() => handleRemove(Number(id))}
              onClick={() => {
                Modal.confirm({
                  title: 'Confirmação',
                  content: 'Deseja realmente excluir o modelo?',
                  okText: 'Confirmar',
                  onOk: () => handleRemove(Number(id)),
                  cancelText: 'Cancelar',
                  footer: (_, { OkBtn, CancelBtn }) => (
                    <>
                      <CancelBtn />
                      <OkBtn />
                    </>
                  ),
                });
              }}
              icon={<DeleteOutlined />}
              loading={isLoading}
              hidden={id === 'novo-modelo' || !isActive}
              disabled={!hasPermission(603)}
            >
              Excluir modelo
            </Button>
            <Button
              type="primary"
              onClick={handleSaveTemplate}
              icon={<CheckOutlined />}
              loading={isLoading}
              disabled={!hasPermission(602)}
            >
              Salvar modelo
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={3}>{title}</Title>
        </Col>
      </Row>
      <Row>
        <Col span={14} style={{ marginRight: 16 }}>
          <Paragraph>Descrição</Paragraph>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder='Digite aqui o descrição'
            required
          />
        </Col>
        <Col span={4}>
          <Paragraph>Ativo</Paragraph>
          <Switch
            checked={isActive}
            onChange={handleIsActive}
            checkedChildren='Sim'
            unCheckedChildren='Não'
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey={id !== 'novo-modelo' ? '2' : '1'}>
            <TabPane tab="Documento" key="1" disabled={id !== 'novo-modelo'}>
              <Row>
                <Col span={24}>
                  <Title level={5}>Informe o documento modelo (.pdf)</Title>

                  <Upload.Dragger
                    listType="picture" {...props} disabled={isLoading}
                  >
                    <div style={{ padding: '10px' }}>
                      <p className="ant-upload-drag-icon">
                        <CloudUploadOutlined />
                      </p>
                      <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
                      <p className="ant-upload-hint">Suporte para upload único</p>
                    </div>
                  </Upload.Dragger>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Signatários" key="2">
              <Row style={{ marginBottom: 10 }}>
                <Col span={24}>
                  <Space>
                    <Button type="default" onClick={handleAddSignatory} icon={<PlusOutlined />} loading={isLoading}>
                      Adicionar signatário
                    </Button>
                    <Button type="default" onClick={handleRemoveSignatory} icon={<DeleteOutlined />} loading={isLoading}>
                      Remover signatário
                    </Button>

                    <Button
                      type="default"
                      onClick={() => navigate(`/modelos/posicionar-campos/${id}`)}
                      icon={<AimOutlined />}
                      loading={isLoading}
                      hidden={id === 'novo-modelo' || !isActive}
                    >
                      Posicionar campos
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    columns={columns}
                    dataSource={participants}
                    rowKey={(record) => record.signingPriority}
                    loading={isLoading}
                    size="small"
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Acesso" key="3" disabled={user?.id !== template?.userId}>
              <Row>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <Button type="dashed" onClick={() => setIsModalUser(true)} icon={<PlusOutlined />}>
                    Adicionar usuário
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    columns={columnsAassociatedUsers}
                    dataSource={dataTable}
                    rowKey={(record) => record.id}
                    loading={isLoading}
                    size="small"
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Modal
        open={isModalUser}
        width={800}
        onCancel={() => setIsModalUser(false)}
        title='Selecionar usuário'
        footer={[
        ]}
      >
        <Row style={{ marginBottom: 16 }}>
          <Col span={12}>
            <Input
              placeholder='Pesquisar usuário por nome'
              onChange={(e) => setSearch(e.target.value)}
              allowClear
              suffix={
                <SearchOutlined />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columnsUser}
              rowKey={(record) => record.id}
              dataSource={users}
              loading={isLoading}
              size="small"
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

import React from 'react';
import { Input, InputProps } from 'antd';

interface InputMaskCNPJProps extends InputProps { }

export const InputMaskCNPJ: React.FC<InputMaskCNPJProps> = (props) => {
  const applyCNPJFormat = (value: string) => {
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    return value;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = applyCNPJFormat(value);
    // Atualize o valor do input com a máscara aplicada
    e.target.value = maskedValue;
    // Dispare o evento onChange para atualizar o estado do componente
    if (props.onChange) {
      const event = { ...e, target: { ...e.target, value: maskedValue } };
      props.onChange(event as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Input
      {...props}
      onChange={handleInputChange}
      maxLength={18} // Define o comprimento máximo com a máscara aplicada
    />
  );
};

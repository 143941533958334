/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Col, DatePicker, FloatButton, Form, Input, Layout, Menu, MenuProps, Modal, Result, Row, Space, Spin, Tooltip, Typography, notification } from 'antd';
import {
  EditOutlined,
  CloudDownloadOutlined,
  ArrowLeftOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  ExpandOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IFieldsTemplate, ISignerResp, SignerService } from '../../shared/services/api';
import { isCPFValid, validateCPF } from '../../shared/hooks';
import { Document, Page, pdfjs } from 'react-pdf';
import { InputMaskCNPJ, InputMaskCPF, InputMaskPhone } from '../../shared/components';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import dayjs from 'dayjs';

const { Content, Header, Footer } = Layout;
const { Title } = Typography;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

interface ISigner {
  name: string;
  cpf: string;
}

export const SignDocument = () => {
  const { key = '' } = useParams<'key'>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isFinalized, setIsFinalized] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTokenOpen, setIsModalTokenOpen] = useState(false);
  const [form] = Form.useForm();
  const [token, setToken] = useState('');
  const [signer, setSigner] = useState<ISigner>({} as ISigner);
  const [signerResp, setSignerResp] = useState<ISignerResp>({} as ISignerResp);

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scaleDocument, setScaleDocument] = useState<number>(1.5);

  const [pdfDataUri, setPdfDataUri] = useState<string>();

  const [participantForm] = Form.useForm();
  const [fields, setFields] = useState<IFieldsTemplate[]>([]);
  const [isModalForm, setIsModalForm] = useState(false);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      SignerService.sign(key)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
            navigate('/documentos');
          } else {
            setSignerResp(result);
            getPDF(`${result.documentFileUrl}?version=0&fileType=0`);

            if (result.fields && result.fields.length > 0) {
              setFields(result.fields);
              setIsModalForm(true);
            }
          }
        });
    }
    getData();
  }, [key]);

  async function getPDF(url: string) {
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();

    setPdfDataUri(await pdfDoc.saveAsBase64({ dataUri: true }));
  }

  async function getPDF2(url: string, fields: IFieldsTemplate[]) {
    try {
      const response = await fetch(url);
      const pdfBytes = await response.arrayBuffer();

      const pdfDoc = await PDFDocument.load(pdfBytes);
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const pages = pdfDoc.getPages();
      const textSize = 12;

      for (let x = 0; x < fields.length; x++) {
        const element = fields[x];
        const pageIndex = pages[element.pageNumber - 1];
        const pageWidth = pageIndex.getWidth();
        const pageHeight = pageIndex.getHeight();
        const ajust = 850 - pageHeight + 15;
        pageIndex.drawText(`${element.value ? element.value : ''}`, {
          x: element.positionX - element.boundsX - 10,
          y: element.boundsY - element.positionY - ajust,
          size: textSize,
          font: helveticaFont,
          lineHeight: 15,
          color: rgb(0, 0, 0),
        });
      }

      setPdfDataUri(await pdfDoc.saveAsBase64({ dataUri: true }));

    } catch (error) {
      console.error('Erro ao obter o ArrayBuffer do PDF:', error);
    }
  }

  const handleSign = () => {
    form.resetFields();
    setIsModalOpen(true);
  };

  const handleFinalize = () => {
    SignerService.create(key, { name: signer.name, cpf: signer.cpf, token, fields })
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setIsFinalized(true);
        }
      });
  };

  const handleResendToken = () => {
    sendToken();
  };

  const handleSend = () => {
    const participant = form.getFieldsValue();

    if (isCPFValid(participant.cpf)) {
      setSigner(participant);
      sendToken();
      setIsModalOpen(false);
      setIsModalTokenOpen(true);
    } else {
      notification.error({
        message: 'CPF inválido. Por favor verifique.'
      });

      return;
    }
  };

  const sendToken = () => {
    SignerService.requestToken(key)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          notification.success({
            message: 'Token enviado com sucesso!'
          });
        }
      });
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const goToNextPage = () => {
    if (numPages === pageNumber) {
      return;
    }
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const goToPreviousPage = () => {

    setPageNumber((prevPageNumber) =>
      prevPageNumber === 1 ? 1 : prevPageNumber - 1
    );
  };

  const zoomIn = () => {
    if (scaleDocument === 2) {
      return;
    }
    setScaleDocument((prevScale) => prevScale + 0.5);
  };

  const zoomOut = () => {
    if (scaleDocument === 1) {
      return;
    }
    setScaleDocument((prevScale) => prevScale - 0.5);
  };

  const getFieldComponent = (type: number, description: string) => {
    switch (type) {
      case 1:
        return <Input maxLength={100} placeholder={description} />;
      case 2:
        return <Input maxLength={100} placeholder={description} />;
      case 3:
        return <Input type="email" placeholder={description} />;
      case 4:
        return <DatePicker format='DD/MM/YYYY' placeholder={description} />;
      case 5:
        return <InputMaskCPF maxLength={11} placeholder={description} />;
      case 6:
        return <InputMaskCNPJ maxLength={14} placeholder={description} />;
      case 7:
        return <InputMaskPhone type='tel' maxLength={11} placeholder={description} />;
      case 8:
        return <Input type='number' prefix="R$" placeholder={description} />;
      default:
        return null;
    }
  };

  const handleSaveParticipantForm = (values: any) => {
    const novosDadosArray = fields.map((field) => ({
      ...field,
      value: validateData(field.type, field.key, values)
    }));
    setFields(novosDadosArray);
    getPDF2(`${signerResp.documentFileUrl}?version=0&fileType=0`, novosDadosArray);
    setIsModalForm(false);
  };

  function validateData(type: number, key: string, values: any) {
    if (type === 4) {
      return formatDate(values[`${key}`]);
    }

    if (type === 8) {
      return `R$ ${values[`${key}`]}`;
    }

    return values[`${key}`];
  }

  function formatDate(dateIn: Date) {
    const date = new Date(dateIn);
    const dateDayjs = dayjs(date);
    return dateDayjs.format('DD/MM/YYYY');
  }

  return (
    <Spin spinning={isLoading}>
      <Layout>
        <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            background: '#fff',
            justifyContent: 'space-between',
            padding: 0
          }}
        >
          <Button
            type='text'
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate('/')}
            style={{
              fontSize: '16px',
              height: 64,
            }}
          >
            Voltar para login
          </Button>
          <Space.Compact style={{ height: '65px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <img src="../assets/logo.svg" alt="Logo" style={{ height: '45px' }} />
            <Title level={3} style={{ marginTop: 8, marginLeft: 8 }}>Engmax Sign</Title>
          </Space.Compact>
          <div>
            <Tooltip title='Baixar documento'>
              <Button
                type='text'
                icon={<CloudDownloadOutlined />}
                style={{ marginRight: 15 }}
                href={signerResp.documentFileUrl}
              />
            </Tooltip>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={handleSign}
              hidden={isFinalized}
              style={{ marginRight: 24 }}
            >
              Assinar
            </Button>
          </div>
        </Header>
        <Content>
          {!isModalOpen && !isModalTokenOpen && !isFinalized && (
            <Row>
              <Col span={24} style={{ height: 'calc(100vh - 70px)' }}>

                {/* <iframe
                  src={`https://docs.google.com/viewer?url=${pdfDataUri}&embedded=true&rm=minimal`}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                ></iframe> */}

                <iframe
                  width='100%'
                  height='100%'
                  src={pdfDataUri}
                  frameBorder="0"
                />

                {/* <Document
                  file={signerResp.documentFileUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading='Carregando arquivo...'
                >
                  <Page
                    className='centralizar-pdf'
                    pageNumber={pageNumber}
                    scale={scaleDocument}
                    renderMode='canvas'
                    canvasBackground='#FFFFFF'
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    renderForms={false}
                  />
                </Document>

                <Tooltip title="Mais zoom">
                  <FloatButton type="primary" shape='square' icon={<ZoomInOutlined />} style={{ right: 24 }} onClick={zoomIn} />
                </Tooltip>
                <Tooltip title="Menos zoom">
                  <FloatButton type="primary" shape='square' icon={<ZoomOutOutlined />} style={{ right: 94 }} onClick={zoomOut} />
                </Tooltip>
                <Tooltip title="Próxima página">
                  <FloatButton type="primary" shape='square' icon={<RightOutlined />} style={{ right: 164 }} onClick={goToNextPage} />
                </Tooltip>
                <Tooltip title="Página anterior">
                  <FloatButton type="primary" shape='square' icon={<LeftOutlined />} style={{ right: 234 }} onClick={goToPreviousPage} />
                </Tooltip> */}
              </Col>
            </Row>
          )}

          {isModalOpen && !isModalTokenOpen && !isFinalized && (
            <Row
              style={{
                padding: 24,
                height: 280,
                background: '#FFFFFF',
              }}
            >
              <Col span={8}></Col>
              <Col span={8}>
                <Title level={3}>Confirme seus dados</Title>
                <Form
                  layout="vertical"
                  form={form}
                  size='large'
                  hideRequiredMark
                  onFinish={handleSend}
                  initialValues={{
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Nome é um campo obrigatório' }]}
                      >
                        <Input placeholder='Nome completo' />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="cpf"
                        rules={[
                          { required: true, message: 'CPF é um campo obrigatório' },
                          { validator: validateCPF }
                        ]}
                      >
                        {/* <Input placeholder='CPF' /> */}
                        <InputMaskCPF placeholder='CPF' />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Button onClick={() => setIsModalOpen(false)} style={{ marginRight: 8 }} htmlType="button">
                        Cancelar
                      </Button>
                      <Button type="primary" htmlType="submit" loading={isLoading}>
                        Salvar alterações
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col span={8}></Col>
            </Row>
          )}

          {!isModalOpen && isModalTokenOpen && !isFinalized && (
            <Row
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
                background: '#FFFFFF',
              }}
            >
              <Col span={24} style={{ justifyContent: 'center', display: 'flex', marginTop: 30 }}>
                <Title level={4}>Insira o código de 6 letras que enviamos por email para {signerResp.email}</Title>
              </Col>
              <Col span={24} style={{ justifyContent: 'center', display: 'flex', marginTop: 30 }}>
                <Input
                  placeholder='AAAAAA'
                  onChange={(e) => setToken(e.target.value.toUpperCase())}
                  value={token}
                  maxLength={6}
                  bordered={false}
                  style={{
                    fontSize: '3em',
                    textTransform: 'uppercase',
                    letterSpacing: '1em',
                    width: 'calc(6em + 5em)'
                  }}
                />
              </Col>
              <Col span={24} style={{ justifyContent: 'center', display: 'flex', marginTop: 30 }}>
                <Button type='link' onClick={handleResendToken}>
                  Reenviar token via e-mail
                </Button>
              </Col>
              <Col span={24} style={{ justifyContent: 'center', display: 'flex', marginTop: 30 }}>
                <Button type="primary" onClick={handleFinalize}>
                  Finalizar assinatura
                </Button>
              </Col>
            </Row>
          )}

          {isFinalized && (
            <Result
              status='success'
              title='Documento assinado com sucesso!'
              subTitle='Quando todos assinarem, você receberá um e-mail com o documento assinado.'
              extra={
                <Button type="primary" key="console" href={signerResp.documentFileUrl} >
                  Baixar documento original
                </Button >}
            />
          )}

        </Content>
      </Layout>

      <Modal
        open={isModalForm}
        width={600}
        closeIcon={false}
        footer={[
        ]}
      >
        <Form
          form={participantForm}
          size='large'
          onFinish={handleSaveParticipantForm}
          initialValues={{}}
        >
          <Row>
            <Col span={24}>
              <Title level={4}>Por favor, preencha o formulário para continuar</Title>
            </Col>
          </Row>
          {signerResp.fields?.map((field) => (
            <Row key={field.key}>
              <Col span={24}>
                <Form.Item
                  name={field.key}
                  rules={[{ required: field.isRequired, message: `${field.description} é um campo obrigatório` }]}
                >
                  {getFieldComponent(field.type, field.description)}
                </Form.Item>
              </Col>
            </Row>
          ))}
          <Row gutter={16}>
            <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Continuar
              </Button>
            </Col>
          </Row>
        </Form>

      </Modal>
    </Spin >
  );
};

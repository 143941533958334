/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, DatePicker, Form, Modal, Radio, Row, Select, Typography, notification } from 'antd';
import {
  DownloadOutlined
} from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import dayjs, { Dayjs } from 'dayjs';
import { ReportsService } from '../../shared/services/api';

const { Title } = Typography;
const { RangePicker } = DatePicker;

interface ISearch {
  typeDocument: number;
  createdAtInterval?: [Dayjs, Dayjs]
  documentStatus?: number;
}

export const Report = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>();
  // const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

  const handleSearch = () => {
    form.validateFields()
      .then((values) => {
        // console.log(checkedList);
        downloadFile(values);
      })
      .catch((error) => {
        Modal.warning({
          title: 'Atenção',
          content: error.errorFields[0].errors[0]
        });
        return;
      });
  };

  const handleClearSearch = () => {
    form.resetFields();
  };

  const downloadFile = async (search: ISearch) => {
    try {
      setIsLoading(true);

      let aux = `?type=${search.typeDocument}`;

      if (search.createdAtInterval) {
        aux = `${aux}&createdAtInterval=${(search.createdAtInterval ? `${search.createdAtInterval[0].format('YYYY-MM-DD')},${search.createdAtInterval[1].format('YYYY-MM-DD')}` : '')}`;
      }

      if (search.documentStatus) {
        aux = `${aux}&documentStatus=${search.documentStatus}`;
      }

      const accessToken = sessionStorage.getItem('accessToken') || undefined;

      const response = await fetch(
        `${process.env.REACT_APP_URL_BASE}/Reports${aux}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      ).finally(() => { setIsLoading(false); });
      const blob = await response.blob();

      // Verifica o tipo de conteúdo retornado pela API
      const contentType = response.headers.get('content-type');
      let extension = '';
      if (contentType === 'text/csv') {
        extension = 'csv';
      } else if (contentType === 'application/pdf') {
        extension = 'pdf';
      } else {
        // Se o tipo de conteúdo não for suportado, você pode tratar aqui
        console.error('Tipo de arquivo não suportado.');
        return;
      }


      // Cria um URL temporário para o arquivo baixado
      const url = window.URL.createObjectURL(new Blob([blob]));

      // Cria um link para download e simula o clique para baixar o arquivo
      const link = document.createElement('a');
      link.href = url;
      const currentDate = dayjs().format('YYYYMMDD_HHmmss');
      link.setAttribute('download', `arquivo_${currentDate}.${extension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };

  // const plainOptions = [
  //   { label: 'Descrição', value: 1 },
  //   { label: 'Nome do Arquivo', value: 2 },
  //   { label: 'Data Criação', value: 3 },
  //   { label: 'Criado por', value: 4 },
  //   { label: 'Status', value: 5 },
  //   { label: 'Modelo', value: 6 },
  //   { label: 'Fluxo', value: 7 },
  //   { label: 'Data Finalização', value: 8 },
  // ];
  // const defaultCheckedList = [1, 2, 3, 4, 5];

  // const onChange = (list: CheckboxValueType[]) => {
  //   setCheckedList(list);
  // };

  return (
    <Content>
      <Row>
        <Col span={24}>
          <Title level={3}>Relatório</Title >
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card size="small" title="Filtros" bordered={false}>
            <Form
              layout='inline'
              form={form}
              onFinish={handleSearch}
              initialValues={{
                createdAtInterval: undefined,
                documentStatus: undefined,
                typeDocument: 0
              }}
            >
              <Form.Item
                name="createdAtInterval"
                label="Período"
                rules={[{ required: true, message: 'Período é um campo obrigatório' }]}
              >
                <RangePicker format='DD/MM/YYYY' />
              </Form.Item>
              <Form.Item
                name="documentStatus"
                label="Status"
              >
                <Select
                  defaultValue=''
                  style={{ width: 120 }}
                  allowClear
                  options={[
                    { value: '', label: 'Todos' },
                    { value: '0', label: 'Pendente' },
                    { value: '1', label: 'Finalizado' },
                    { value: '2', label: 'Anulado' },
                    { value: '3', label: 'Cancelado' },
                    { value: '4', label: 'Expirado' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="typeDocument"
                label="Tipo"
              >
                <Radio.Group>
                  <Radio value={0}>PDF</Radio>
                  <Radio value={1}>CSV</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Gerar relatório
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="dashed" danger onClick={handleClearSearch} loading={isLoading}>
                  Limpar filtros
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col
          span={24}
          style={{
            marginTop: '30px',
          }}
        >
          <Checkbox.Group
            options={plainOptions}
            defaultValue={defaultCheckedList}
            onChange={onChange}
          />
        </Col>
      </Row> */}
    </Content>
  );
};

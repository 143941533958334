import { useEffect, useState } from 'react';
import { Button, Col, Pagination, Row, Space, Table, Tag, Tooltip, Typography, notification } from 'antd';
import {
  ArrowLeftOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { DocumentsService, IDocument } from '../../shared/services/api';
import { Dayjs } from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

const { Title } = Typography;

interface ISearch {
  description?: string;
  documentStatus?: string;
  createdAtInterval?: [Dayjs, Dayjs]
}

export const MySignatures = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<IDocument[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limiteLinhas, setLimiteLinhas] = useState(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useState<ISearch>({} as ISearch);

  useEffect(() => {
    setIsLoading(true);

    const limiteLinhas: number = parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS || '', 10);
    setLimiteLinhas(limiteLinhas);

    DocumentsService.getAll(currentPage, limiteLinhas, search.description, search.documentStatus, (search.createdAtInterval ? `${search.createdAtInterval[0].format('YYYY-MM-DD')},${search.createdAtInterval[1].format('YYYY-MM-DD')}` : ''), true)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setDataTable(result.data);
          setTotalCount(result.total);
        }
      });
  }, [currentPage, search]);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const viewDocument = async (key: string) => {
    navigate(`/documentos/detalhe/${key}`);
  };

  const columns: ColumnsType<IDocument> = [
    {
      title: 'Documento',
      dataIndex: 'description',
      key: 'description',
      render: (_, { description, fileName, participants, deadline }) => {
        const total = participants.filter(x => x.isSigner).length;
        const remaining = participants.filter(x => x.isSigner && x.hasSigned).length;

        return (
          <>
            <Title level={4}>{description}</Title>
            <small>{remaining} de {total} Assinaturas</small><br />
            <small>{fileName}</small><br />
            {deadline && (<><small>Prazo final: {moment(deadline).format('DD/MM/YYYY')}</small></>)}
          </>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => {
        if (status === 0) {
          return <Tag color="orange">Pendente</Tag>;
        } else if (status === 1) {
          return <Tag color="green">Finalizado</Tag>;
        } else if (status === 2) {
          return <Tag color="yellow">Anulado</Tag>;
        } else if (status === 3) {
          return <Tag color="red">Cancelado</Tag>;
        } else if (status === 4) {
          return <Tag color="default">Expirado</Tag>;
        } else if (status === 5) {
          return <Tag color="blue">Assinatura Digital Pendente</Tag>;
        }
      }
    },
    {
      title: 'Data Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, { createdAt }) => {
        return moment(createdAt).format('DD/MM/YYYY HH:mm');
      }
    },
    {
      key: 'actions',
      render: (_, { key }) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip title="Ver documento">
            <Button type="text" shape="circle" icon={<EyeOutlined />} onClick={() => viewDocument(key)} />
          </Tooltip>
        </Space>
      ),
    }
  ];

  return (
    <>
      <Row style={{ paddingBottom: '16px' }}>
        <Col span={24}>
          <Button type="text" onClick={() => navigate('/documentos')} icon={<ArrowLeftOutlined />}>Voltar para documentos</Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={3}>Documentos para assinar</Title >
        </Col>
      </Row>

      <Row
        style={{ marginTop: '30px' }}
      >
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataTable}
            rowKey={(record) => record.key}
            loading={isLoading}
            size="small"
            pagination={false}
          />
          <Pagination
            pageSize={limiteLinhas}
            style={{ float: 'right', margin: '5px' }}
            current={currentPage}
            onChange={onChangePage}
            total={totalCount}
            showTotal={total => `Total ${total} registro(s)`}
          />
        </Col>
      </Row>
    </>
  );
};

import { Api } from './axios-config';
import { setHeaderAuthorization } from '../../utils/apiHelper';

export interface IRole {
  id: number;
  name: string;
  group: string;
  permissions: React.Key[];
  users: IUserRole[];
}

export interface IUserRole {
  id: number;
  name: string;
}

export enum Permissions {
  ceateDocument,
  generateReport
}

export interface IRoleReq {
  name: string;
  group: string;
  permissions: React.Key[];
  users: number[];
}

export interface IMine {
  id: number;
  name: string;
  permissions: number[];
}

type TRoleCount = {
  currentPage: number;
  data: IRole[];
  perPage: number;
  total: number;
}

const getAll = async (currentPage: number = 1, perPage: number = 5): Promise<TRoleCount | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/roles?currentPage=${currentPage}&perPage=${perPage}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: number): Promise<IRole | Error> => {
  try {
    const { data } = await Api.get(`/roles/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IRoleReq): Promise<number | Error> => {
  try {
    setHeaderAuthorization();

    return await Api.post('/roles', dados);

  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const update = async (id: number, dados: IRoleReq): Promise<number | Error> => {
  try {
    setHeaderAuthorization();

    return await Api.put(`/roles/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const exclude = async (id: number): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.delete(`/roles/${id}`);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const mine = async (): Promise<IMine[] | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get<IMine[]>('/roles/mine');

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');

  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao buscar os registros.');
  }
};

export const RolesService = {
  getAll,
  getById,
  create,
  update,
  exclude,
  mine,
};

import { setHeaderAuthorization } from '../../utils/apiHelper';
import { Api } from './axios-config';

export interface ITokenResult {
  token: string;
}

const tokenGeneration = async (): Promise<ITokenResult | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get('/Auth/token-generator');

    if (data) {
      return data;
    }

    return new Error('Erro ao gerar o token de acesso a API.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao gerar o token de acesso a API.');
  }
};

export const AuthService = {
  tokenGeneration
};

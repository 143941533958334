import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import 'antd/dist/reset.css';
import './shared/styles/global.css';
import { AuthProvider } from 'react-oidc-context';
import { PermissionProvider } from './shared/contexts/PermissionContext';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
// import { observability, plugin } from './config/observability';
import { plugin } from './config/observability';

const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_URL_REDIRECT
};

export const App = () => {
  // observability();

  return (
    <AuthProvider {...oidcConfig} >
      <AppInsightsContext.Provider value={plugin}>
        <PermissionProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </PermissionProvider>
      </AppInsightsContext.Provider>
    </AuthProvider>
  );
};

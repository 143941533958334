import { Button, Col, Layout, Row, Space, Spin, Typography } from 'antd';
import {
  UserOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { useAuth } from 'react-oidc-context';
import { Footer } from 'antd/es/layout/layout';

const { Content, Header } = Layout;
const { Title } = Typography;

export const Login = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const handleSubmit = async () => {
    auth.signinRedirect();
  };

  const openHelpCenter = () => {
    const url = 'https://engmaxsign.tomticket.com/kb';
    window.open(url, '_blank');
  };

  return (
    <Spin spinning={auth.isLoading}>
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ background: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
          <Space.Compact style={{ paddingLeft: 24, height: '65px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <img src="../assets/logo.svg" alt="Logo" style={{ height: '45px' }} />
            <Title level={3} style={{ marginTop: 8, marginLeft: 8 }}>Engmax Sign</Title>
          </Space.Compact>
          <Space.Compact>
            <Button
              type='text'
              icon={<QuestionCircleOutlined />}
              onClick={openHelpCenter}
              style={{
                height: 64,
              }}
            >
              Central de Ajuda
            </Button>
            <Button
              type='text'
              icon={<FileDoneOutlined />}
              onClick={() => navigate('/validar-assinatura-documentos')}
              style={{
                height: 64,
              }}
            >
              Verificar documento
            </Button>
            <Button
              type='text'
              icon={<UserOutlined />}
              onClick={handleSubmit}
              style={{
                height: 64,
              }}
            >
              Iniciar sessão
            </Button>
          </Space.Compact>
        </Header>
        <Content style={{ textAlign: 'center' }}>
          <Row
            style={{
              height: '526px',
              width: '100%',
              maxWidth: '1500px',
              margin: 'auto',
              position: 'relative'
            }}
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}
              // style={{ display: 'flex', flexDirection: 'column', padding: 100 }}
              style={{
                width: '40%',
                maxWidth: 480,
                height: 245,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: '8%',
                margin: 'auto',
                zIndex: 1
              }}
            >
              <Title level={1}
                style={{
                  textAlign: 'left',
                  fontSize: 50,
                  marginTop: 12,
                  marginBottom: 12,
                  marginRight: 0,
                  marginLeft: 0
                }}
              >Engmax Sign
              </Title>
              <Title level={4} type='secondary' style={{ textAlign: 'left', marginBottom: 30 }}>
                Conectando Pessoas e Processos com Assinaturas Digitais
              </Title>
              <div
                style={{
                  marginTop: 64,
                  lineHeight: 40,
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  style={{
                    width: 200,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 18
                  }}
                >
                  Iniciar sessão
                </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}
              style={{
                width: '45%',
                maxWidth: 598,
                height: 324,
                position: 'absolute',
                right: '8%',
                margin: 'auto',
                top: 0,
                bottom: 0
              }}
            >
              <img src="assets/bg.svg" alt="Banner" style={{ width: 500, height: 'auto', marginRight: 100 }} />
            </Col>
          </Row>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Engmax Sign ©{new Date().getFullYear()} Created by Engmax Engenharia
        </Footer>
      </Layout>
    </Spin >
  );
};


/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

interface IModifyPageProps {
  pdf: string;
  result: any;
  bounds: any;
  buttonType: string;
  resetButtonType: () => void;
}

export const ModifyPage: React.FC<IModifyPageProps> = ({ pdf, result, bounds, buttonType, resetButtonType }) => {

  useEffect(() => {
    if (buttonType === 'download') {
      modifyPdf();
      resetButtonType();
    }
  }, [buttonType]);

  async function modifyPdf() {
    const existingPdfBytes = await fetch(pdf).then(res => {
      return (res.arrayBuffer());
    });

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const pages = pdfDoc.getPages();
    const textSize = 12;

    result.forEach(async (res: any) => {
      if (res.type === 'text') {
        pages[res.page - 1].drawText(res.text, {
          x: res.x - bounds.x - 10,
          y: bounds.y - res.y - 23,
          size: textSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
          maxWidth: res.ref.current.getBoundingClientRect().width,
          lineHeight: 15
        });
      }

      if (res.type === 'freehand') {
        const pathData = 'M ' +
          res.arr
            .map((p: { get: (arg0: string) => any; }) => {
              return `${p.get('x')},${p.get('y')}`;
            })
            .join(' L ');
        pages[res.page - 1].moveTo(0, pages[0].getHeight());
        pages[res.page - 1].drawSvgPath(pathData, {
          borderColor: rgb(0.95, 0.1, 0.1),
        });
      }

    });

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = 'na2';
    link.download = fileName;
    link.click();

  }

  return (
    <div>

    </div>
  );
};

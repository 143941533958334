import { Api } from './axios-config';
import { setHeaderAuthorization } from '../../utils/apiHelper';
import { IFieldsTemplate } from './TemplatesService';

export interface ISigner {
  name: string;
  cpf: string;
  token: string
  fields?: IFieldsTemplate[];
}

export interface ISignerResp {
  email: string;
  documentDescription: string;
  documentFileUrl: string;
  canRefuse: boolean;
  fileName: string;
  fields?: IFieldsTemplate[];
}

const requestToken = async (key: string): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.post(`/sign/${key}/request-token`);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao solicitar o token');
  }
};

const create = async (key: string, signer: ISigner): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.post(`/sign/${key}`, signer);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao assinar o documento');
  }
};

const sign = async (key: string): Promise<ISignerResp | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/sign/${key}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error('Erro ao consultar o registro.');
  }
};

export const SignerService = {
  create,
  requestToken,
  sign
};

import { appInsights } from '../../../../../config/observability';

export const errorInterceptor = (error: any) => {
  appInsights.trackEvent({
    name: 'errorInterceptor',
    properties: {
      error_support: error.response.data,
      statusText: error.response.statusText,
      data: error.response.config.data,
      token: error.response.config.headers.Authorization
    }
  });

  if (error.message === 'Network Error') {
    return Promise.reject(new Error('Erro de conexão.'));
  }

  if (error.message === 'Request failed with status code 400') {
    return Promise.reject(new Error('Erro ao enviar os dados para o servidor'));
    // return Promise.reject(new Error(JSON.stringify(error.response.data.errors)));
  }

  if (error.message === 'Request failed with status code 500') {
    return Promise.reject(new Error('Erro ao enviar os dados para o servidor. Entre em contato com o suporte.'));
    // return Promise.reject(new Error(JSON.stringify(error.response.data.errors)));
  }

  if (error.response?.status === 401) {
    //
  }

  return Promise.reject(error);
};

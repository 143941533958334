/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, Select, Row, Col, Button, Typography, Spin, Form, Modal, Card, Space, TablePaginationConfig, Table, Tooltip, notification, Tabs, Popconfirm } from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  CheckOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { ContactsService, IContact, ISigningFlow, ISigningFlowUpdate, SigningFlowsServices } from '../../shared/services/api';
import { useDebounce } from '../../shared/hooks';
import { ColumnsType } from 'antd/es/table';
import { IUser, UsersService } from '../../shared/services/api/UsersService';
import { usePermission } from '../../shared/contexts/PermissionContext';

const { Title } = Typography;
const { TabPane } = Tabs;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

export const GroupsFlow: React.FC = () => {
  const navigate = useNavigate();
  const { id = 'novo-fluxo' } = useParams<'id'>();
  const [signingFlow, setSigningFlow] = useState<ISigningFlow>({} as ISigningFlow);

  const { user } = usePermission();

  const [form] = Form.useForm();
  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState<string>();

  // Contatos
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [isModalContact, setIsModalContact] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  // const validacaoSignatarios = (lastGroup: IGroupFlow, allGroups: IGroupFlow[]) => {
  //   if (lastGroup && !allGroups.some((group) => group.participant === lastGroup?.participant)) {
  //     notification.warning({
  //       message: `O signatário ${lastGroup.participant}  ja informado!`
  //     });
  //   }
  // };

  // Usuários
  const [dataTable, setDataTable] = useState<IUser[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [isModalUser, setIsModalUser] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [tableParamsUser, setTableParamsUser] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  useEffect(() => {
    async function getTemplate(id: string) {
      if (id !== 'novo-fluxo') {
        setTitle('Editar fluxo');
        setIsLoading(true);
        await SigningFlowsServices.getById(Number(id))
          .then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              notification.error({
                message: result.message
              });
              goBack();
            } else {
              setSigningFlow(result);
              form.setFieldsValue(result);

              if (result.associatedUsers) {
                setDataTable(result.associatedUsers);
              }
            }
          });
      } else {
        setTitle('Novo fluxo');
      }
    }

    getTemplate(id);
  }, []);

  const goBack = () => {
    navigate('/fluxos');
  };

  const handleSave = async () => {
    form.validateFields()
      .then((values) => {
        if (id !== 'novo-fluxo') {
          updateFlow(values);
        } else {
          createFlow(values);
        }
      })
      .catch((error) => {
        Modal.warning({
          title: 'Atenção',
          content: error.errorFields[0].errors[0]
        });
        return;
      });
  };

  const createFlow = (data: ISigningFlow) => {
    setIsLoading(true);
    // const usersIds = dataTable.map(user => user.id);
    data.associatedUsers = [];
    SigningFlowsServices.create(data)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
          goBack();
        } else {
          notification.success({
            message: 'Fluxo criado com sucesso!'
          });
          goBack();
        }
      });
  };

  const updateFlow = (data: ISigningFlowUpdate) => {
    setIsLoading(true);
    const usersIds = dataTable.map(user => user.id);
    data.associatedUsers = usersIds ? usersIds : [];

    SigningFlowsServices.update(Number(id), data)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
          goBack();
        } else {
          notification.success({
            message: 'Fluxo atualizado com sucesso!'
          });
          goBack();
        }
      });
  };

  const handleAddSignatario = (contactName: string = '', contactEmail: string = '') => {
    const currentParticipants = form.getFieldValue('participants') || [];
    const newSignatory = {
      name: contactName,
      email: contactEmail,
      signingRole: 1,
      isSigner: true,
      canRefuse: false,
    };

    const updatedParticipants = [
      ...currentParticipants,
      {
        ...newSignatory,
        signingPriority: currentParticipants.length + 1,
      },
    ];

    form.setFieldsValue({
      participants: updatedParticipants,
    });
  };

  const handleSelectContact = (contact: IContact) => {
    handleAddSignatario(contact.name, contact.email);
    // const currentParticipants = form.getFieldValue('participants') || [];
    // const newSignatory = {
    //   name: contact.name,
    //   email: contact.email,
    //   signingRole: 1,
    //   isSigner: true,
    //   canRefuse: false,
    // };

    // const updatedParticipants = [
    //   ...currentParticipants,
    //   {
    //     ...newSignatory,
    //     signingPriority: currentParticipants.length + 1,
    //   },
    // ];

    // form.setFieldsValue({
    //   participants: updatedParticipants,
    // });

    setIsModalContact(false);
  };

  useEffect(() => {
    ContactsService.getAll(tableParams.pagination?.current, tableParams.pagination?.pageSize)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setContacts(result.data);

          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: result.total,
            },
          });
        }
      });
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
  ) => {
    setTableParamsUser({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setContacts([]);
    }
  };

  useEffect(() => {
    debounce(() => {
      UsersService.getAll(tableParams.pagination?.current, tableParams.pagination?.pageSize, search, false)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            setUsers(result.data);

            setTableParamsUser({
              ...tableParams,
              pagination: {
                ...tableParams.pagination,
                total: result.total,
              },
            });
          }
        });
    });
  }, [JSON.stringify(tableParams), search]);

  const handleSelectUser = (user: IUser) => {
    if (dataTable.some((aux) => aux.id === user.id)) {
      Modal.warning({
        title: 'Atenção',
        content: 'O usuário ja foi informado.'
      });
      return false;
    } else {
      setDataTable([...dataTable, user]);
      setIsModalUser(false);
      setSearch('');
    }
  };

  const handleTableChangeUser = (
    pagination: TablePaginationConfig,
  ) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setUsers([]);
    }
  };

  const handleRemoveAssocietedUser = async (id: number) => {
    const updatedData = dataTable.filter(item => item.id !== id);
    setDataTable(updatedData);
  };

  const columnsContacts: ColumnsType<IContact> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      key: 'actions',
      render: (record: IContact) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip
            title='Selecionar grupo'
          >
            <Button type="text" shape="circle" icon={<CheckCircleOutlined />} onClick={() => handleSelectContact(record)} />
          </Tooltip>
        </Space>
      ),
    }
  ];

  const columnsAassociatedUsers: ColumnsType<IUser> = [
    {
      title: 'Usuário',
      dataIndex: 'name',
      key: 'name',
    },
    {
      key: 'actions',
      width: '10%',
      render: (record: IUser) => (
        <Popconfirm
          title="Deseja realmente remover este usuario?"
          onConfirm={() => handleRemoveAssocietedUser(record.id)}
          okText="Sim"
          cancelText="Não"
          placement="left"
        >
          <Button type="text" shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    }
  ];

  const columnsUser: ColumnsType<IUser> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Selecionar',
      key: 'actions',
      width: '10%',
      render: (record: IUser) => (
        <Space size="small" style={{ justifyContent: 'center', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<CheckCircleOutlined />} onClick={() => handleSelectUser(record)} />
        </Space>
      ),
    }
  ];

  return (
    <>
      <Spin spinning={isLoading}>
        <Row style={{ paddingBottom: '16px' }}>
          <Col span={4}>
            <Button type="text" onClick={() => navigate('/fluxos')} icon={<ArrowLeftOutlined />}>Voltar para fluxos</Button>
          </Col>
          <Col span={20} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button type="primary" onClick={handleSave} icon={<CheckOutlined />} loading={isLoading}>
              Salvar fluxo
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={3}>{title}</Title>
          </Col>
        </Row>
        <Tabs defaultActiveKey={'1'}>
          <TabPane tab="Dados" key="1">
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  hideRequiredMark
                  onFinish={() => { }}
                  initialValues={signingFlow}
                >
                  <Row>
                    <Form.Item name="id" style={{ display: 'none' }}>
                      <Input readOnly name="id" />
                    </Form.Item>
                    <Col span={12}>
                      <Form.Item
                        name="description"
                        label="Descrição"
                        rules={[{ required: true, message: 'Descrição é um campo obrigatório' }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Space>
                      <Button
                        type="default"
                        onClick={() => handleAddSignatario()}
                        icon={<PlusOutlined />}
                        loading={isLoading}
                      >
                        Adicionar manualmente
                      </Button>
                      <Button
                        type="default"
                        onClick={() => setIsModalContact(true)}
                        icon={<UserOutlined />}
                        loading={isLoading}
                      >
                        Buscar contato
                      </Button>
                      <Tooltip placement="right" title="A ordem de envio seguirá os números atribuídos, do menor para o maior. Caso opte por informar um número igual para todos os signatários, os e-mails serão enviados para todos os assinantes sem uma ordem específica." >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  </Row>
                  <Row>
                    <Col span={12} style={{ marginTop: 16 }}>
                      <Form.List
                        name="participants"
                        rules={[
                          {
                            validator: async (_, participants) => {
                              if (!participants || participants.length < 1) {
                                return Promise.reject(new Error('Informe o(s) signatário(s)'));
                              }
                            },
                          },
                        ]}
                      >
                        {(fields, { remove }, { errors }) => (
                          <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                            {fields.map((field) => (
                              <Card
                                size="small"
                                // title={`Signatário ${field.name + 1}`}
                                key={field.key}
                                extra={
                                  <Button
                                    type='text'
                                    danger
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                    icon={<DeleteOutlined />}
                                  >
                                    Remover
                                  </Button>
                                }
                              >
                                <Form.Item
                                  label="Ordem de assinatura"
                                  name={[field.name, 'signingPriority']}
                                >
                                  <Select
                                    style={{ width: 80 }}
                                    onChange={() => { }}
                                    options={[
                                      { value: '1', label: '1' },
                                      { value: '2', label: '2' },
                                      { value: '3', label: '3' },
                                      { value: '4', label: '4' },
                                      { value: '5', label: '5' },
                                      { value: '6', label: '6' },
                                      { value: '7', label: '7' },
                                      { value: '8', label: '8' },
                                      { value: '9', label: '9' },
                                    ]}
                                  />
                                </Form.Item>

                                <Form.Item
                                  label="Nome do signatário"
                                  name={[field.name, 'name']}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[{ required: true, message: 'Nome do signatário é um campo obrigatório' }]}
                                >
                                  <Input type='text' />
                                </Form.Item>

                                <Form.Item
                                  label="E-mail do signatário"
                                  name={[field.name, 'email']}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={
                                    [
                                      { required: true, message: 'E-mail do signatário é um campo obrigatório' },
                                      { type: 'email', message: 'O e-mail informado no signatário é inválido' }
                                    ]
                                  }
                                >
                                  <Input type='email' />
                                </Form.Item>
                              </Card>
                            ))}

                            <Form.ErrorList errors={errors} />
                          </div>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Acesso" key="2" disabled={user?.id !== signingFlow?.userId}>
            <Row>
              <Col span={12} style={{ marginBottom: 10 }}>
                <Button type="dashed" onClick={() => setIsModalUser(true)} icon={<PlusOutlined />}>
                  Adicionar usuário
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  columns={columnsAassociatedUsers}
                  dataSource={dataTable}
                  rowKey={(record) => record.id}
                  loading={isLoading}
                  size="small"
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Spin>

      <Modal
        open={isModalContact}
        width={800}
        onCancel={() => setIsModalContact(false)}
        title='Selecionar contato'
        footer={[
        ]}
      >
        <Table
          columns={columnsContacts}
          rowKey={(record) => record.id}
          dataSource={contacts}
          loading={isLoading}
          size="small"
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        />
      </Modal>

      <Modal
        open={isModalUser}
        width={800}
        onCancel={() => setIsModalUser(false)}
        title='Selecionar usuário'
        footer={[
        ]}
      >
        <Row style={{ marginBottom: 16 }}>
          <Col span={12}>
            <Input
              placeholder='Pesquisar usuário por nome'
              onChange={(e) => setSearch(e.target.value)}
              allowClear
              suffix={
                <SearchOutlined />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columnsUser}
              rowKey={(record) => record.id}
              dataSource={users}
              loading={isLoading}
              size="small"
              pagination={tableParamsUser.pagination}
              onChange={handleTableChangeUser}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

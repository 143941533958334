import { useAuth } from 'react-oidc-context';
import { Container } from '../shared/components';
import { AppRoutes } from './app.routes';
import { AuthRoutes } from './auth.routes';

export const Routes = () => {
  const auth = useAuth();

  if (auth.isAuthenticated) {
    const token = auth.user?.access_token;
    if (token) {
      sessionStorage.setItem('accessToken', token);
      sessionStorage.setItem('profile', JSON.stringify(auth.user?.profile));
    }

    auth.stopSilentRenew();

    return (
      <Container>
        <AppRoutes />
      </Container>
    );
  } else {
    return <AuthRoutes />;
  }
};

import { Api } from './axios-config';
import { setHeaderAuthorization } from '../../utils/apiHelper';

export interface IContact {
  id: number;
  name: string;
  email: string;
}

export interface ISignKey {
  name: string;
  cpf: string
}

export interface ISign {
  name: string;
  email: string;
  cpf: string;
  rule: number;
}

type TContactCount = {
  currentPage: number;
  data: IContact[];
  perPage: number;
  total: number;
}

const getAll = async (currentPage: number = 1, perPage: number = 5): Promise<TContactCount | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/contacts?currentPage=${currentPage}&perPage=${perPage}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

// const getById = async (id: number): Promise<IContact | Error> => {
//   try {
//     const { data } = await Api.get(`/contacts/${id}`);

//     if (data) {
//       return data;
//     }

//     return new Error('Erro ao consultar o registro.');
//   } catch (error) {
//     console.error(error);
//     return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
//   }
// };

const create = async (dados: IContact): Promise<number | Error> => {
  try {
    setHeaderAuthorization();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...dataSend } = dados;
    const { data } = await Api.post<IContact>('/contacts', dataSend);

    if (data) {
      return data.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const update = async (id: number, dados: IContact): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.put(`/contacts/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const exclude = async (id: number): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.delete(`/contacts/${id}`);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const ContactsService = {
  getAll,
  // getById,
  create,
  update,
  exclude,
};

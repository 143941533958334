/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useRef, useState } from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IAutoTextAreaProps {
  val: string;
  unique_key: any;
  onTextChange: (key: any, text: string, div: any) => void;
  style: {
    visibility?: 'visible' | 'hidden';
    color?: string;
    fontWeight?: 'normal' | 'bold';
    fontSize?: number;
    zIndex?: number;
    position?: 'absolute';
    left?: string;
    top?: string;
  };
  children?: React.ReactNode;
}

export const AutoTextArea: React.FC<IAutoTextAreaProps> = ({ val, onTextChange, unique_key, style, children }) => {
  const textAreaRef = useRef<any>(null);
  const divAreaRef = useRef(null);
  const [text, setText] = useState(val);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const [parentHeight, setParentHeight] = useState('auto');

  useEffect(() => {
    setParentHeight(`${textAreaRef.current.scrollHeight}px`);
    setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
  }, [text]);

  const onChangeHandler = (event: any) => {
    setTextAreaHeight('auto');
    setParentHeight(`${textAreaRef.current.scrollHeight}px`);
    setText(event.target.value);
  };

  const onBlurHandler = () => {
    onTextChange(unique_key, text, divAreaRef);
  };

  useEffect(() => {
    textAreaRef.current.focus();
  }, []);

  return (
    <div
      ref={divAreaRef}
      style={{ minHeight: parentHeight, ...style }}
    >
      <textarea
        ref={textAreaRef}
        rows={1}
        style={{ height: textAreaHeight, zIndex: 10, background: 'transparent', border: 'none', color: 'red', fontFamily: 'helvetica' }}
        value={text}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
      />
      {children}
    </div>
  );
};


import { createContext, useContext, useState, useEffect } from 'react';
import { IMe, UsersService } from '../services/api/UsersService';
import { useAuth } from 'react-oidc-context';

interface PermissionContextProps {
  children: React.ReactNode;
}

interface PermissionContextValue {
  user: IMe | null;
  hasPermission: (permissionId: number) => boolean;
}

const PermissionContext = createContext<PermissionContextValue | undefined>(undefined);

const PermissionProvider: React.FC<PermissionContextProps> = ({ children }) => {
  const auth = useAuth();
  const [user, setUser] = useState<IMe | null>(null);

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    const groups = [
      'Supervisor',
      'GU_TRANSP_ADM_ASDIG',
      'GU_TRANSP_SUPORTE_ASDIG',
      'GU_TRANSP_USERS_ASDIG'
    ];

    const getUserData = async () => {
      const profile = sessionStorage.getItem('profile');
      if (profile) {
        const profileData = JSON.parse(profile);
        const roles = profileData.realm_access.roles;
        roles.sort();
        const resultado = roles.filter((item: string) => groups.includes(item));
        await UsersService.me(resultado[0])
          .then((result) => {
            if (result instanceof Error) {
              console.log(result.message);
            } else {
              setUser(result);
            }
          });
      }
    };

    getUserData();
  }, [auth.isAuthenticated]);

  const hasPermission = (permissionId: number): boolean => {
    if (user?.permissions.some(permission => permission.id === 0)) {
      return true;
    }

    return user?.permissions.some(permission => permission.id === permissionId) ?? false;
  };

  return (
    <PermissionContext.Provider value={{ user, hasPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};

const usePermission = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error('usePermission must be used within a PermissionProvider');
  }
  return context;
};

export { PermissionProvider, usePermission };

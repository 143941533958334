import { useState } from 'react';
import { Button, Col, Layout, Result, Row, Space, Typography, Upload, UploadFile, UploadProps, notification, theme } from 'antd';
import {
  CloudUploadOutlined,
  CheckOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { DocumentsService } from '../../shared/services/api';
import { RcFile } from 'antd/es/upload';
import { useNavigate } from 'react-router';

const { Content, Header } = Layout;
const { Title } = Typography;

export const ValidateSignedDocument = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [documentValid, setDocumentValid] = useState<boolean>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleCheckCompliance = async () => {
    if (fileList.length <= 0) {
      notification.warning({
        message: 'Para continuar, Informe o documento'
      });

      return false;
    }

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as RcFile);
    });

    setIsLoading(true);
    await DocumentsService.verifyDocument(formData)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setDocumentValid(result.isValid);
        }
      });
  };

  const handleClear = () => {
    setDocumentValid(undefined);
    setFileList([]);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length > 0) {
        notification.info({
          message: 'Já existe um arquivo selecionado agurdando a verificação de conformidades'
        });
        return false;
      }
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  if (documentValid !== undefined) {
    return (
      <Row>
        <Col span={24}>
          {
            documentValid ? (
              <Result
                status="success"
                title="Sucesso!"
                subTitle="Todas as assinaturas do documento estão válidas."
                extra={[
                  <Row key="document">
                    <Col span={24} style={{ marginBottom: 10 }}>
                      <Button type="default" onClick={handleClear} >
                        Verificar outro documento
                      </Button>
                    </Col>
                  </Row>,
                  <Button type='link' onClick={() => navigate('/')} key="login">
                    voltar para login
                  </Button>,
                ]}
              />
            ) : (
              <Result
                status="error"
                title="Aviso"
                subTitle="Você submeteu um documento sem assinatura reconhecível ou com assinatura corrompida."
                extra={[
                  <Row key="document">
                    <Col span={24} style={{ marginBottom: 10 }}>
                      <Button type="default" onClick={handleClear} >
                        Verificar outro documento
                      </Button>
                    </Col>
                  </Row>,
                  <Button type='link' onClick={() => navigate('/')} key="login">
                    voltar para login
                  </Button>,
                ]}
              />
            )
          }
        </Col>
      </Row>
    );
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ background: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
        <Button
          type='text'
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate('/')}
          style={{
            fontSize: '16px',
            height: 64,
          }}
        >
          Voltar para login
        </Button>
        <Space.Compact style={{ height: '65px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <img src="../assets/logo.svg" alt="Logo" style={{ height: '45px' }} />
          <Title level={3} style={{ marginTop: 8, marginLeft: 8 }}>Engmax Sign</Title>
        </Space.Compact>
        <Button type="primary" onClick={handleCheckCompliance} icon={<CheckOutlined />} style={{ marginRight: 24 }}>
          Validar
        </Button>
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px' }}>
        <div style={{ marginTop: 16, padding: 24, minHeight: 380, background: colorBgContainer }}>
          <Row
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '30px'
            }}
          >
            <Col span={24} style={{ textAlign: 'center' }}>
              <Title level={4}>Selecione o documento a ser validado</Title >
            </Col>
            <Col span={24}>
              <Upload.Dragger
                listType="picture" {...props} disabled={isLoading}
              >
                <div style={{ padding: '10px' }}>
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
                  <p className="ant-upload-hint">Suporte para upload único</p>
                </div>
              </Upload.Dragger>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { setHeaderAuthorization } from '../../utils/apiHelper';
import { IUser } from './UsersService';
import { Api } from './axios-config';

export interface ITemplate {
  id: number;
  description: string;
  isActive: boolean;
  key: string;
  fileName?: string;
  participants?: IParticipantTemplate[];
  fields?: IFieldsTemplate[];
  associatedUsers?: IUser[];
  userId?: number;
}

export interface IParticipantTemplate {
  id: number;
  name: string;
  email: string;
  signingPriority: number;
  isSigner: boolean;
  templateId?: number;
}

export interface IFieldsTemplate {
  id?: number;
  description: string;
  key: string;
  type: number;
  positionX: number;
  positionY: number;
  boundsX: number;
  boundsY: number;
  pageNumber: number;
  templateId?: number;
  participantId: number;
  isRequired: boolean;
  value?: any;
  ref?: any;
}

export interface ITemplateUpdate {
  description: string;
  isActive: boolean;
  key: string;
  fileName?: string;
  participants?: IParticipantTemplate[];
  fields?: IFieldsTemplate[];
  associatedUsers?: number[];
}

type TTemplateData = {
  currentPage: number;
  data: ITemplate[];
  perPage: number;
  total: number;
}

const getAll = async (currentPage: number = 1, perPage: number = 5): Promise<TTemplateData | Error> => {
  try {
    setHeaderAuthorization();

    //&description=${description}
    const { data } = await Api.get<TTemplateData>(`/templates?currentPage=${currentPage}&perPage=${perPage}`);
    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: number): Promise<ITemplate | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/templates/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (template: Omit<ITemplate, 'id'>): Promise<ITemplate | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.post<ITemplate>('/templates', template);

    if (data) {
      return data;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: number, data: ITemplateUpdate): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.put(`/templates/${id}`, data);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.delete(`/templates/${id}`);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const TemplatesService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById
};

import { setHeaderAuthorization } from '../../utils/apiHelper';
import { Api } from './axios-config';

export interface IPermission {
  id: number;
  description: string;
}

const getAll = async (): Promise<IPermission[] | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get('/permissions');

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const PermissionsService = {
  getAll
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, Space, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { DrawArea } from './DrawArea';
import {
  UndoOutlined,
  RedoOutlined,
  EditOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined
} from '@ant-design/icons';

const { Paragraph } = Typography;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

interface ISinglePageProps {
  pdf: string;
  cursor: string;
  pageChange: (num: any) => void;
  getBounds: (obj: any) => void;
  flag: string;
  changeFlag: () => void;
  getPaths: (el: any) => void;
  resetButtonType: () => void;
  buttonType: string;
  undo: () => void;
  redo: () => void;
  addText: () => void;
}

export const SinglePage: React.FC<ISinglePageProps> = ({ pdf, cursor, pageChange, getBounds, flag, changeFlag, getPaths, resetButtonType, buttonType, undo, redo, addText }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);


  useEffect(() => {
    pageChange(pageNumber);
  }, [pageNumber]);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: any) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <Space>
        <Tooltip title="Página anterior">
          <Button icon={<DoubleLeftOutlined />} onClick={previousPage} disabled={pageNumber <= 1} />
        </Tooltip>
        <Paragraph>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </Paragraph>
        <Tooltip title="Próxima página">
          <Button icon={<DoubleRightOutlined />} onClick={nextPage} disabled={pageNumber >= numPages} />
        </Tooltip>
        <Button icon={<EditOutlined />} onClick={addText}>Adicionar texto</Button>
      </Space>
      <Document
        file={pdf}
        options={options}
        onSourceError={(err) => console.log(err)}
        onSourceSuccess={() => console.log('SUCCESS')}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={() => console.log('ERR')}
      >
        <DrawArea
          getPaths={getPaths}
          page={pageNumber}
          flag={flag}
          getBounds={getBounds}
          changeFlag={changeFlag}
          cursor={cursor}
          buttonType={buttonType}
          resetButtonType={resetButtonType}
        >
          <Page pageNumber={pageNumber}
            renderAnnotationLayer={true}
            renderTextLayer={true}
          >
          </Page>
        </DrawArea>
      </Document>
    </>
  );
};

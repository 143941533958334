import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const OBSERVABILITY_KEY = process.env.REACT_APP_OBSERVABILITY_KEY;
const plugin = new ReactPlugin() as any;

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: OBSERVABILITY_KEY,
    extensions: [plugin],
    extensionConfig: {},
    enableAutoRouteTracking: false,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: false,
    enableRequestHeaderTracking: false,
    enableResponseHeaderTracking: false,
  },
});

if (OBSERVABILITY_KEY) {
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags = env.tags || [];
    env.tags['ai.cloud.role'] = 'engmaxsign';
  });
}

export { appInsights, plugin };

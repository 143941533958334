import { useCallback, useEffect, useState } from 'react';
import { IUser, UsersService } from '../../shared/services/api/UsersService';
import { Button, Col, Input, Pagination, Row, Tag, Typography, notification } from 'antd';
import {
  SearchOutlined,
  // DownloadOutlined
  FileExcelOutlined
} from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import { useDebounce } from '../../shared/hooks';
import moment from 'moment';
import dayjs from 'dayjs';

const { Title } = Typography;

export const Users = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<IUser[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limiteLinhas, setLimiteLinhas] = useState(10);
  const [search, setSearch] = useState<string>('');
  const { debounce } = useDebounce();

  const getData = useCallback(() => {
    setIsLoading(true);

    const limiteLinhas: number = parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS || '', 10);
    setLimiteLinhas(limiteLinhas);
    debounce(() => {
      UsersService.getAll(currentPage, limiteLinhas, search, true)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            setDataTable(result.data);
            setTotalCount(result.total);
          }
        });
    });
  }, [currentPage, search]);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const exportCSV = async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken') || undefined;

      const response = await fetch(
        `${process.env.REACT_APP_URL_BASE}/Users/export-users`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );
      if (!response.ok) {
        throw new Error('Falha ao exportar os dados');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const currentDate = dayjs().format('YYYYMMDD_HHmmss');
      const currentFileName = `usuarios_${currentDate}.csv`;
      const a = document.createElement('a');
      a.href = url;
      a.download = currentFileName;
      a.click();
    } catch (error) {
      console.error('Erro ao exportar os dados:', error);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnsType<IUser> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm']
    },
    {
      title: 'Último acesso',
      dataIndex: 'email',
      key: 'loginTime',
      responsive: ['sm'],
      render: (_, { loginTime }) => {
        if (!loginTime) return '';

        return moment(loginTime).format('DD/MM/YYYY HH:mm');
      }
    },
    {
      title: 'Perfil',
      dataIndex: 'roles',
      key: 'roles',
      render: (_, { roles }) => (
        <>
          {roles.map((tag) => {
            return (
              <Tag color="default" key={tag.id}>
                {tag.name}
              </Tag>
            );
          })}
        </>
      ),
    },
    // {
    //   key: 'actions',
    //   render: (record: IUser) => (
    //     <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
    //       <Button type="text" shape="circle" icon={<FormOutlined />} onClick={() => buscarDadosUsuario(record)} />
    //     </Space>
    //   ),
    // }
  ];

  return (
    <>
      <Row>
        <Col span={14}>
          <Title level={3}>Usuários</Title >
        </Col>
      </Row>
      <Row style={{ marginBottom: 16 }}>
        <Col span={12}>
          <Input
            placeholder='Pesquisar usuário por nome'
            onChange={(e) => setSearch(e.target.value)}
            allowClear
            suffix={
              <SearchOutlined />
            }
          />
        </Col>
        <Col span={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="dashed" onClick={exportCSV} icon={<FileExcelOutlined />} loading={isLoading}>
            Exportar CSV
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataTable}
            rowKey={(record) => record.id}
            loading={isLoading}
            size="small"
            pagination={false}
          />
          <Pagination
            pageSize={limiteLinhas}
            style={{ float: 'right', margin: '5px' }}
            current={currentPage}
            onChange={onChangePage}
            total={totalCount}
            showTotal={total => `Total ${total} registro(s)`}
          />
        </Col>
      </Row>
    </>
  );
};

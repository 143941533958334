/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Input, Row, Space, Typography, Transfer, Tabs, Table, Popconfirm, Modal, notification, Spin, Form } from 'antd';
import {
  CheckOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { IUser, UsersService } from '../../shared/services/api/UsersService';
import { useDebounce } from '../../shared/hooks';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { IPermission, IRole, IRoleReq, IUserRole, PermissionsService, RolesService } from '../../shared/services/api';
import type { TransferProps } from 'antd';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

interface RecordType {
  key: string;
  title: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

export const DetailRoles = () => {
  const { id = 'novo-perfil' } = useParams<'id'>();
  const navigate = useNavigate();
  const { debounce } = useDebounce();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState<string>('');
  const [listPermissions, setListPermissions] = useState<RecordType[]>([]);

  const [nomePerfil, setNomePerfil] = useState<string>('');
  const [group, setGroup] = useState<string>('');
  const [permissions, setPermissions] = useState<React.Key[]>([]);

  const [dataTable, setDataTable] = useState<IUserRole[]>([]);

  // Usuários
  const [users, setUsers] = useState<IUser[]>([]);
  const [isModalUser, setIsModalUser] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  useEffect(() => {
    if (id !== 'novo-perfil') {
      setIsLoading(true);
      setTitle('Editar perfil de usuário');
      RolesService.getById(Number(id))
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            setNomePerfil(result.name);
            setGroup(result.group);
            form.setFieldsValue(result);
            setPermissions(result.permissions.map((numero) => numero.toString()));
            setDataTable(result.users);
          }
        });
    } else {
      setTitle('Novo perfil de usuário');
    }
  }, [id]);

  useEffect(() => {
    async function getPermissions() {
      setIsLoading(true);
      await PermissionsService.getAll()
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            const recordType = result
              .filter(permission => permission.id !== 0)
              .map((permission) => {
                const aux = {
                  key: permission.id.toString(),
                  title: permission.description
                };
                return aux;
              });

            setListPermissions(recordType);
          }
        });
    }

    getPermissions();
  }, []);

  // Salvar usuários
  const handleSave = () => {
    form.validateFields()
      .then((values) => {
        const usersIds = dataTable.map(user => user.id);

        if (id !== 'novo-perfil') {
          updateRole(Number(id), { name: values.name, group: values.group, permissions, users: usersIds });
        } else {
          createRole({ name: values.name, group: values.group, permissions, users: usersIds });
        }
      })
      .catch((error) => {
        Modal.warning({
          title: 'Atenção',
          content: error.errorFields[0].errors[0]
        });
        return;
      });


    // if (id !== 'novo-perfil') {
    //   setIsLoading(true);
    //   const usersIds = dataTable.map(user => user.id);
    //   RolesService.update()
    //     .then((result) => {
    //       setIsLoading(false);
    //       if (result instanceof Error) {
    //         notification.error({
    //           message: result.message
    //         });
    //       } else {
    //         notification.success({
    //           message: 'Registro alterado com sucesso!'
    //         });
    //         navigate('/perfis-usuarios');
    //       }
    //     });
    // } else {
    //   if (nomePerfil === '') {
    //     notification.warning({
    //       message: 'Informe o nome do perfil'
    //     });
    //     return;
    //   }

    //   setIsLoading(true);
    //   const usersIds = dataTable.map(user => user.id);
    //   RolesService.create({ name: nomePerfil, group, permissions, users: usersIds })
    //     .then((result) => {
    //       setIsLoading(false);
    //       if (result instanceof Error) {
    //         notification.error({
    //           message: result.message
    //         });
    //       } else {
    //         notification.success({
    //           message: 'Registro criado com sucesso!'
    //         });
    //         navigate('/perfis-usuarios');
    //       }
    //     });
    // }
  };

  const createRole = (data: IRoleReq) => {
    setIsLoading(true);
    RolesService.create(data)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          notification.success({
            message: 'Registro criado com sucesso!'
          });
          navigate('/perfis-usuarios');
        }
      });
  };

  const updateRole = (id: number, data: IRoleReq) => {
    RolesService.update(id, data)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          notification.success({
            message: 'Registro alterado com sucesso!'
          });
          navigate('/perfis-usuarios');
        }
      });
  };

  const filterOption = (inputValue: string, option: RecordType) =>
    option.title.indexOf(inputValue) > -1;

  const handleChange: TransferProps['onChange'] = (nextTargetKeys, direction, moveKeys) => {
    setPermissions(nextTargetKeys);
  };

  // Consultar usuários
  const handleRemove = async (id: number) => {
    const updatedData = dataTable.filter(item => item.id !== id);
    setDataTable(updatedData);
  };

  useEffect(() => {
    debounce(() => {
      UsersService.getAll(tableParams.pagination?.current, tableParams.pagination?.pageSize, search, true)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            setUsers(result.data);

            setTableParams({
              ...tableParams,
              pagination: {
                ...tableParams.pagination,
                total: result.total,
              },
            });
          }
        });
    });
  }, [JSON.stringify(tableParams), search]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
  ) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setUsers([]);
    }
  };

  const handleSelectUser = (user: IUser) => {
    if (dataTable.some((aux) => aux.id === user.id)) {
      Modal.warning({
        title: 'Atenção',
        content: 'O usuário ja foi informado.'
      });
      return false;
    } else {
      setDataTable([...dataTable, user]);
      setIsModalUser(false);
      setSearch('');
    }
  };

  const columns: ColumnsType<IUserRole> = [
    {
      title: 'Usuário',
      dataIndex: 'name',
      key: 'name',
    },
    {
      key: 'actions',
      width: '10%',
      render: (record: IUser) => (
        <Popconfirm
          title="Deseja realmente remover este usuario?"
          onConfirm={() => handleRemove(record.id)}
          okText="Sim"
          cancelText="Não"
          placement="left"
        >
          <Button type="text" shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    }
  ];

  const columnsUser: ColumnsType<IUser> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Selecionar',
      key: 'actions',
      width: '10%',
      render: (record: IUser) => (
        <Space size="small" style={{ justifyContent: 'center', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<CheckCircleOutlined />} onClick={() => handleSelectUser(record)} />
        </Space>
      ),
    }
  ];

  const transferLocale = {
    itemUnit: 'item',
    itemsUnit: 'itens',
    notFoundContent: 'Lista vazia',
    searchPlaceholder: 'Procurar aqui',
    remove: 'Remover',
    selectAll: 'Selecionar todos os itens',
    selectCurrent: 'Selecionar página atual',
    selectInvert: 'Inverter seleção atual',
    removeAll: 'Remover todos os itens',
    removeCurrent: 'Remover página atual'
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <Row style={{ paddingBottom: '16px' }}>
          <Col span={4}>
            <Button type="text" onClick={() => navigate('/perfis-usuarios')} icon={<ArrowLeftOutlined />}>
              Voltar para Perfis de usuários
            </Button>
          </Col>
          <Col span={20} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button type="primary" onClick={handleSave} icon={<CheckOutlined />} loading={isLoading}>
              Salvar perfil
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={3}>{title}</Title>
          </Col>
        </Row>
        <Row>
          <Form
            form={form}
            labelCol={{ span: 8 }}
            layout="vertical"
            hideRequiredMark
            onFinish={() => { }}
          >
            <Row>
              <Form.Item name="id" style={{ display: 'none' }}>
                <Input readOnly name="id" />
              </Form.Item>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Nome do Perfil"
                  rules={[{ required: true, message: 'Nome do Perfil é um campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="group"
                  label="Grupo"
                  rules={[{ required: true, message: 'Grupo é um campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
        {/* <Row>
          <Col span={12}>
            <Paragraph>Nome do Perfil</Paragraph>
            <Input
              value={nomePerfil}
              onChange={(e) => setNomePerfil(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Paragraph>Grupo</Paragraph>
            <Input
              value={group}
              onChange={(e) => setGroup(e.target.value)}
            />
          </Col>
        </Row> */}
        <Tabs defaultActiveKey="1">
          <TabPane tab="Permissões" key="1">
            <Row>
              <Col span={24}>
                <Transfer
                  dataSource={listPermissions}
                  titles={['Lista de permissões', 'Permissões atribuídas']}
                  showSearch
                  listStyle={{
                    width: '100%',
                    height: 300,
                  }}
                  filterOption={filterOption}
                  targetKeys={permissions}
                  onChange={handleChange}
                  render={(item) => item.title}
                  locale={transferLocale}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Usuários" key="2">
            <Row>
              <Col span={12} style={{ marginBottom: 10 }}>
                <Button type="dashed" onClick={() => setIsModalUser(true)} icon={<PlusOutlined />}>
                  Adicionar usuário
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataTable}
                  rowKey={(record) => record.id}
                  loading={isLoading}
                  size="small"
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>

        <Modal
          open={isModalUser}
          width={800}
          onCancel={() => setIsModalUser(false)}
          title='Selecionar usuário'
          footer={[
          ]}
        >
          <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
              <Input
                placeholder='Pesquisar usuário por nome'
                onChange={(e) => setSearch(e.target.value)}
                allowClear
                suffix={
                  <SearchOutlined />
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columnsUser}
                rowKey={(record) => record.id}
                dataSource={users}
                loading={isLoading}
                size="small"
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Modal>
      </Spin>
    </>
  );
};

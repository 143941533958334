import React from 'react';
import { Input, InputProps } from 'antd';

interface InputMaskCPFProps extends InputProps { }

export const InputMaskCPF: React.FC<InputMaskCPFProps> = (props) => {
  const applyCPFFormat = (value: string) => {
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return value;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = applyCPFFormat(value);
    // Atualize o valor do input com a máscara aplicada
    e.target.value = maskedValue;
    // Dispare o evento onChange para atualizar o estado do componente
    if (props.onChange) {
      const event = { ...e, target: { ...e.target, value: maskedValue } };
      props.onChange(event as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Input
      {...props}
      onChange={handleInputChange}
      maxLength={14} // Define o comprimento máximo com a máscara aplicada
    />
  );
};

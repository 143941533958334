import { useCallback, useEffect, useState } from 'react';
import { Button, Col, ConfigProvider, Empty, Pagination, Row, Space, Table, Tag, Tooltip, Typography, notification } from 'antd';
import {
  PlusOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { ITemplate, TemplatesService } from '../../shared/services/api';
import { usePermission } from '../../shared/contexts/PermissionContext';

const { Title } = Typography;

export const Templates = () => {
  const { hasPermission } = usePermission();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<ITemplate[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limiteLinhas, setLimiteLinhas] = useState(10);

  const newTemplate = () => {
    navigate('/modelos/novo-modelo');
  };

  const detailTemplate = (id: number) => {
    navigate(`/modelos/${id}`);
  };

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const getData = useCallback(() => {
    setIsLoading(true);

    const limiteLinhas: number = parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS || '', 10);
    setLimiteLinhas(limiteLinhas);

    TemplatesService.getAll(currentPage, limiteLinhas)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setDataTable(result.data);
          setTotalCount(result.total);
        }
      });
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnsType<ITemplate> = [
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ativo',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (_, { isActive }) => {
        if (isActive) {
          return <Tag color="green">Ativo</Tag>;
        } else if (!isActive) {
          return <Tag color="default">Inativo</Tag>;
        }
      }
    },
    {
      key: 'actions',
      render: (record: ITemplate) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip title='Ver modelo'>
            <Button type="text" shape="circle" icon={<EyeOutlined />} onClick={() => detailTemplate(record.id)} disabled={!hasPermission(602)} />
          </Tooltip>
        </Space>
      ),
    }
  ];

  const customizeRenderEmpty = () => (
    <Empty
      imageStyle={{ height: 60 }}
      description={
        <span>
          Nenhum registro encontrado.
        </span>
      }
    >
      <Button type="primary" icon={<PlusOutlined />} onClick={newTemplate} loading={isLoading} disabled={!hasPermission(600)}>
        Novo modelo
      </Button>
    </Empty>
  );

  return (
    <>
      <Row>
        <Col span={14}>
          <Title level={3}>Modelos</Title >
        </Col>
        <Col span={10} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="primary" onClick={newTemplate} icon={<PlusOutlined />} loading={isLoading} disabled={!hasPermission(600)}>
            Novo modelo
          </Button>
        </Col>
      </Row >
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={columns}
          dataSource={dataTable}
          rowKey={(record) => record.id}
          loading={isLoading}
          size="small"
          pagination={false}
        />
        <Pagination
          pageSize={limiteLinhas}
          style={{ float: 'right', margin: '5px' }}
          current={currentPage}
          onChange={onChangePage}
          total={totalCount}
          showTotal={total => `Total ${total} registro(s)`}
        />
      </ConfigProvider>
    </>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, Form, Input, Modal, Row, Select, Space, Switch, Table, Tabs, Tooltip, Typography, notification } from 'antd';
import {
  FormOutlined,
  DeleteOutlined,
  CheckOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { AutoTextArea, ModifyPage, SinglePage } from '../../shared/components';
import { ColumnsType } from 'antd/es/table';
import { IFieldsTemplate, IParticipantTemplate, ITemplate, TemplatesService } from '../../shared/services/api';
import uuid from 'react-uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { IUser } from '../../shared/services/api/UsersService';

const { Title, Paragraph } = Typography;

export const PositionFields = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id = 'id' } = useParams<'id'>();
  const [template, setTemplate] = useState<ITemplate>({} as ITemplate);
  const [pdfLink, setPdfLink] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);

  const [result, setResult] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [redoStack, setRedoStack] = useState<any[]>([]);
  const [flag, setFlag] = useState('');
  const [bounds, setBounds] = useState<any>({});
  const [isText, setIsText] = useState(false);
  const [buttonType, setButtonType] = useState('');
  const tempRef = useRef(null);

  const [isModalField, setIsModalField] = useState(false);
  const [fields, setFields] = useState<IFieldsTemplate[]>([]);
  const [participants, setParticipants] = useState<IParticipantTemplate[]>([]);

  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    async function getTemplate(id: string) {
      if (id !== 'novo-modelo') {
        setIsLoading(true);
        await TemplatesService.getById(Number(id))
          .then((result) => {
            if (result instanceof Error) {
              notification.error({
                message: result.message
              });
              goToModelos();
            } else {
              setTemplate(result);

              if (result.participants) {
                setParticipants(result.participants);
              }

              if (result.fields) {
                setFields(result.fields);
                const getResult = result.fields.map((field) => {
                  return {
                    id: field.key,
                    x: field.positionX,
                    y: field.positionY,
                    text: field.description,
                    page: field.pageNumber,
                    type: 'text',
                    ref: tempRef
                  };
                });
                setResult(getResult);
              }
              if (result.associatedUsers) {
                setUsers(result.associatedUsers);
              }

              setPdfLink(`https://engmaxsign.com.br/api/downloads/${result.key}?fileType=1`);
            }

            setIsLoading(false);
          });
      }
    }

    getTemplate(id);
  }, []);

  useEffect(() => {
    if (isText) {
      setIsText(false);
    }
  }, [result]);

  const pageChange = (num: any) => {
    setPageNumber(num);
  };

  const getBounds = (obj: any) => {
    setBounds(obj);
  };

  const changeFlag = () => {
    setFlag('');
  };

  const getPaths = (el: any) => {
    setResult(res => [...res, el]);
  };

  const resetButtonType = () => {
    setButtonType('');
  };

  const changeButtonType = (type: any) => {
    setButtonType(type);
  };

  //Function to add text in PDF
  const addText = () => {
    //Flag to change cursor if text
    setIsText(true);
    const drawArea = document.getElementById('drawArea');
    if (drawArea) {
      drawArea.addEventListener('click', (e) => {
        e.preventDefault();
        const key = uuid();//generateKey(e.pageX);
        const adjustment = 10;
        // setResult(result => [...result, {
        //   id: key,
        //   x: e.pageX,
        //   y: e.pageY - adjustment,
        //   text: text,
        //   page: pageNumber,
        //   type: 'text',
        //   ref: tempRef
        // }]);
        form.setFieldValue('key', key);
        form.setFieldValue('ref', tempRef);
        form.setFieldValue('description', '');
        form.setFieldValue('positionX', e.pageX);
        form.setFieldValue('positionY', e.pageY - adjustment);
        form.setFieldValue('pageNumber', pageNumber);
        form.setFieldValue('boundsX', bounds.x);
        form.setFieldValue('boundsY', bounds.y);
        form.setFieldValue('isRequired', true);

        setIsModalField(true);
      }, { once: true });
    }
  };

  const onTextChange = (id: string, txt: string, ref: any) => {
    const indx = result.findIndex(x => x.id === id);
    const item = { ...result[indx] };
    item.text = txt;
    item.ref = ref;
    result[indx] = item;
    setResult(result);

    alterFields(id, txt);
  };

  const alterFields = (id: string, txt: string) => {
    const indx = fields.findIndex(x => x.key === id);
    const item = { ...fields[indx] };
    item.description = txt;
    fields[indx] = item;
    setFields(fields);
  };

  const goToModelos = () => {
    navigate(`/modelos/${template.id}`);
  };

  const handleSaveTemplate = () => {
    // const fieldsDiferentes = (JSON.stringify(template.fields) !== JSON.stringify(fields));
    // const participantsDiferentes = (JSON.stringify(template.participants) !== JSON.stringify(participants));
    const usersIds = users.map(user => user.id);
    setIsLoading(true);
    TemplatesService.updateById(Number(id), {
      description: template.description,
      isActive: template.isActive,
      key: template.key,
      participants: template.participants,
      fields: fields,
      associatedUsers: usersIds ? usersIds : []
    })
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
          goToModelos();
        } else {
          notification.success({
            message: 'Modelo atualizado com sucesso!'
          });
          goToModelos();
        }
      });
  };

  const handleRemoveField = (id: string) => {
    const updateResult = result.filter(item => item.id !== id);
    setResult(updateResult);

    const updateFields = fields.filter(item => item.key !== id);
    setFields(updateFields);
  };

  const hidrateFormWithValues = async (data: IFieldsTemplate) => {
    form.setFieldsValue(data);
    setIsModalField(true);
  };

  const handleOkModal = () => {
    const fieldsForm: IFieldsTemplate = form.getFieldsValue();
    setFields([...fields, fieldsForm]);

    setResult(result => [...result, {
      id: fieldsForm.key,
      x: fieldsForm.positionX,
      y: fieldsForm.positionY,
      text: fieldsForm.description,
      page: fieldsForm.pageNumber,
      type: 'text',
      ref: fieldsForm.ref
    }]);

    form.resetFields();
    setIsModalField(false);
  };

  const handleCancelModal = () => {
    form.resetFields();
    setIsModalField(false);
  };

  //Undo function for both line and text
  const undo = () => {
    const temp = result.pop();
    if (temp) {
      if (temp.type === 'freehand') {
        //Flag for DrawArea reference
        setFlag('undo');
      }
      setRedoStack(stack => [...stack, temp]);
      setResult(result);
    }
  };

  //Redo functionality
  const redo = () => {
    const top = redoStack.pop();
    if (top) {
      if (top.type === 'freehand') {
        //Flag for DrawArea reference
        setFlag('redo');
      }
      setResult(res => [...res, top]);
    }
  };

  const columnsFields: ColumnsType<IFieldsTemplate> = [
    {
      title: 'Campo personalizado',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Signatário',
      dataIndex: 'participantId',
      key: 'participantId',
      render: (_, { participantId }) => {
        return `Signatário ${participantId}`;
      }
    },
    // {
    //   title: 'Tipo',
    //   dataIndex: 'type',
    //   key: 'type',
    // },
    {
      title: 'Página',
      dataIndex: 'pageNumber',
      key: 'pageNumber',
    },
    {
      title: 'Excluir',
      key: 'actions',
      width: '10%',
      render: (record: IFieldsTemplate) => (
        <Space size="small" style={{ justifyContent: 'center', display: 'flex' }}>
          {/* <Button type="text" shape="circle" icon={<FormOutlined />} onClick={() => hidrateFormWithValues(record)} /> */}
          <Button type="text" danger shape="circle" icon={<DeleteOutlined />} onClick={() => handleRemoveField(record.key)} />
        </Space>
      ),
    }
  ];

  return (
    <>
      {
        result.map((res) => {
          if (res.type === 'text') {
            return (
              <AutoTextArea
                key={res.id}
                unique_key={res.id}
                val={res.text}
                onTextChange={onTextChange}
                style={{
                  visibility: (res.page === pageNumber ? 'visible' : 'hidden'),
                  color: 'red',
                  fontWeight: 'normal',
                  fontSize: 12,
                  zIndex: 20,
                  position: 'absolute',
                  left: res.x + 'px',
                  top: res.y + 'px'
                }}
              >

              </AutoTextArea>
            );
          }
          else {
            return (null);
          }
        })
      }

      <Row style={{ paddingBottom: '16px' }}>
        <Col span={4}>
          <Button type="text" onClick={goToModelos} icon={<ArrowLeftOutlined />}>
            Voltar para modelos
          </Button>
        </Col>
        <Col span={20} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Space>
            {/* <Button type="default" onClick={() => changeButtonType('download')} icon={<CheckOutlined />} loading={isLoading}>
              Baixar arquivo
            </Button> */}
            <Button type="primary" onClick={handleSaveTemplate} icon={<CheckOutlined />} loading={isLoading}>
              Salvar modelo
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={3}>Novo modelo</Title >
        </Col>
      </Row>
      <Row>
        <Col span={15}>
          <SinglePage
            resetButtonType={resetButtonType}
            buttonType={buttonType}
            cursor={isText ? 'text' : 'default'}
            pdf={pdfLink}
            pageChange={pageChange}
            getPaths={getPaths}
            flag={flag}
            getBounds={getBounds}
            changeFlag={changeFlag}
            undo={undo}
            redo={redo}
            addText={addText}
          />

          <ModifyPage
            resetButtonType={resetButtonType}
            buttonType={buttonType}
            pdf={pdfLink}
            result={result}
            bounds={bounds}
          />
        </Col>
        <Col span={9}>
          <Table
            columns={columnsFields}
            dataSource={fields}
            rowKey={(record) => record.key}
            loading={isLoading}
            size="small"
            pagination={false}
          />
        </Col>
      </Row>

      <Modal
        open={isModalField}
        title='Configuração do campo'
        footer={[]}
      >
        <Form
          layout="vertical"
          form={form}
          hideRequiredMark
          onFinish={handleOkModal}
          initialValues={{}}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                style={{ display: 'none' }}
                name="key"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Descrição"
                rules={[{ required: true, message: 'Descrição é um campo obrigatório' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="type"
                label="Tipo do campo"
                rules={[{ required: true, message: 'Tipo do campo é um campo obrigatório' }]}
              >
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  options={[
                    { value: 1, label: 'Texto pequeno (100 caracteres)' },
                    { value: 2, label: 'Nome completo' },
                    { value: 3, label: 'E-mail' },
                    { value: 4, label: 'Data (formatada DD/MM/YYYY)' },
                    { value: 5, label: 'CPF' },
                    { value: 6, label: 'CNPJ' },
                    { value: 7, label: 'Telefone' },
                    { value: 8, label: 'Valor (R$)' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="participantId"
                label="Signatário"
                rules={[{ required: true, message: 'O signatário é um campo obrigatório' }]}
              >
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  options={(
                    participants.map(objeto => ({
                      value: objeto.id,
                      label: `Signatário ${objeto.id}`,
                    }))
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="isRequired"
                label="Campo obrigatório"
              >
                <Switch
                  defaultChecked={true}
                  checkedChildren='Sim'
                  unCheckedChildren='Não'
                />
              </Form.Item>
            </Col>
            <Form.Item name="positionX" style={{ display: 'none' }}>
              <Input readOnly name="positionX" />
            </Form.Item>
            <Form.Item name="positionY" style={{ display: 'none' }}>
              <Input readOnly name="positionY" />
            </Form.Item>
            <Form.Item name="pageNumber" style={{ display: 'none' }}>
              <Input readOnly name="pageNumber" />
            </Form.Item>
            <Form.Item name="boundsX" style={{ display: 'none' }}>
              <Input readOnly name="boundsX" />
            </Form.Item>
            <Form.Item name="boundsY" style={{ display: 'none' }}>
              <Input readOnly name="boundsY" />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Confirmar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

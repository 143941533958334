import { useState } from 'react';
import { Button, Col, Result, Row, Typography, Upload, UploadFile, UploadProps, notification } from 'antd';
import {
  CloudUploadOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { DocumentsService } from '../../shared/services/api';
import { RcFile } from 'antd/es/upload';

const { Title } = Typography;

export const ValidateSignedDocumentAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [documentValid, setDocumentValid] = useState<boolean>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleCheckCompliance = async () => {
    if (fileList.length <= 0) {
      notification.warning({
        message: 'Para continuar, Informe o documento'
      });

      return false;
    }

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as RcFile);
    });

    setIsLoading(true);
    await DocumentsService.verifyDocument(formData)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setDocumentValid(result.isValid);
        }
      });
  };

  const handleClear = () => {
    setDocumentValid(undefined);
    setFileList([]);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length > 0) {
        notification.info({
          message: 'Já existe um arquivo selecionado agurdando a verificação de conformidades'
        });
        return false;
      }
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  if (documentValid !== undefined) {
    return (
      <Row>
        <Col span={24}>
          {
            documentValid ? (
              <Result
                status="success"
                title="Sucesso!"
                subTitle="Todas as assinaturas do documento estão válidas."
                extra={
                  <Button type="default" onClick={handleClear}>
                    Verificar outro documento
                  </Button>
                }
              />
            ) : (
              <Result
                status="error"
                title="Aviso"
                subTitle="Você submeteu um documento sem assinatura reconhecível ou com assinatura corrompida."
                extra={
                  <Button type="default" onClick={handleClear}>
                    Verificar outro documento
                  </Button>
                }
              />
            )
          }
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col span={20}>
          <Title level={3}>Validar assinatura</Title >
        </Col>
        <Col span={4} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="primary" onClick={handleCheckCompliance} icon={<CheckOutlined />}>
            Validar
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Col span={24}>
          <Title level={4}>Selecione o documento a ser validado</Title >
        </Col>
        <Col span={24}>
          <Upload.Dragger
            listType="picture" {...props} disabled={isLoading}
          >
            <div style={{ padding: '10px' }}>
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
              <p className="ant-upload-hint">Suporte para upload único</p>
            </div>
          </Upload.Dragger>
        </Col>
      </Row>
    </>
  );
};

import React from 'react';
import { Input, InputProps } from 'antd';

interface InputMaskPhoneProps extends InputProps { }

export const InputMaskPhone: React.FC<InputMaskPhoneProps> = (props) => {
  const applyPhoneFormat = (value: string) => {
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    return value;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = applyPhoneFormat(value);
    // Atualize o valor do input com a máscara aplicada
    e.target.value = maskedValue;
    // Dispare o evento onChange para atualizar o estado do componente
    if (props.onChange) {
      const event = { ...e, target: { ...e.target, value: maskedValue } };
      props.onChange(event as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Input
      {...props}
      onChange={handleInputChange}
      maxLength={15} // Define o comprimento máximo com a máscara aplicada
    />
  );
};

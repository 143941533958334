import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Dropdown, Form, Input, MenuProps, Modal, Row, Space, Spin, Tag, Tooltip, Typography, message, notification } from 'antd';
import { Content } from 'antd/es/layout/layout';
import {
  CopyOutlined,
  ArrowLeftOutlined,
  CloudDownloadOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  IssuesCloseOutlined,
  CloseCircleOutlined,
  CloseSquareOutlined,
  SyncOutlined,
  // SendOutlined,
  EditOutlined
  // DeleteOutlined
} from '@ant-design/icons';
import { DocumentsService, IDocument, IParticipant } from '../../shared/services/api';
import moment from 'moment';
import copy from 'clipboard-copy';
import { usePermission } from '../../shared/contexts/PermissionContext';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

export const DetailDocument = () => {
  const { hasPermission } = usePermission();

  const { key = '' } = useParams<'key'>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [documentFile, setDocumentFile] = useState<IDocument>({} as IDocument);
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [observers, setObservers] = useState<IParticipant[]>([]);
  const [formCancel] = Form.useForm();
  const [isModalCancelOpen, setIsModalCancelOpen] = useState(false);

  const [signerTotal, setSignerTotal] = useState(0);
  const [signerRemaining, setSignerRemaining] = useState(0);

  const [isModalUpdateTitleOpen, setIsModalUpdateTitleOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');

  useEffect(() => {
    getData();
  }, [key]);

  const getData = () => {
    setIsLoading(true);
    DocumentsService.getById(key)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
          navigate('/documentos');
        } else {
          setDocumentFile(result);
          setParticipants(result.participants.filter((participant) => participant.isSigner));
          setObservers(result.participants.filter((participant) => !participant.isSigner));

          setSignerTotal(result.participants.filter(x => x.isSigner).length);
          setSignerRemaining(result.participants.filter(x => x.isSigner && x.hasSigned).length);
        }
      });
  };

  const handleCopy = (url: string | undefined) => {
    if (url) {
      copy(url);
      message.info('Link copiado para a área de transferência');
    }
  };

  const openModalCancel = () => {
    formCancel.resetFields();
    setIsModalCancelOpen(true);
  };

  const handleCancelarDocumento = () => {
    setIsLoading(true);
    DocumentsService.cancelDocument(key, { key: key, reason: formCancel.getFieldsValue().reason })
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
          navigate('/documentos');
        } else {
          formCancel.resetFields();
          setIsModalCancelOpen(false);
          getData();
        }
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(e.target.value);
  };

  const handleOk = () => {
    setIsLoading(true);
    DocumentsService.updateDescription(key, newTitle)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
          navigate('/documentos');
        } else {
          setNewTitle('');
          setIsModalUpdateTitleOpen(false);
          getData();
        }
      });
  };

  const showModalUpdateTitle = () => {
    setIsModalUpdateTitleOpen(true);
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <a rel="noopener noreferrer" href={documentFile.documentFileUrl}>
          Original
        </a>
      ),
      key: 'original',
    },
    {
      label: (
        <a rel="noopener noreferrer" href={documentFile.signedDocumentFileUrl}>
          Assinado
        </a>
      ),
      key: 'assinado',
      disabled: (documentFile.status !== 1),
    },
  ];

  // Verificar se o botão deve ser exibido e/ou habilitado
  const shouldShowButton = (hasPermission(104) && ![2, 3, 4].includes(documentFile.status)) || (!hasPermission(104) && documentFile.status === 0);
  const isButtonDisabled = (!hasPermission(101) && !hasPermission(104));
  return (
    <Content>
      <Spin spinning={isLoading}>
        <Row style={{ paddingBottom: '20px' }}>
          <Col span={4}>
            <Button type="text" onClick={() => navigate('/documentos')} icon={<ArrowLeftOutlined />}>Voltar para documentos</Button>
          </Col>
          <Col span={20} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Space>
              <Tooltip title='Editar titulo documento'>
                <Button
                  shape='circle'
                  type='default'
                  icon={<EditOutlined />}
                  onClick={showModalUpdateTitle}
                  hidden={documentFile.status !== 0}
                  disabled={!hasPermission(102)}
                ></Button>
              </Tooltip>
              <Tooltip title='Sincronizar registro'>
                <Button shape='circle' type='default' icon={<SyncOutlined />} onClick={getData} hidden={documentFile.status !== 0}></Button>
              </Tooltip>
              <Dropdown menu={{ items }} placement="bottomLeft">
                <Button type='default' icon={<CloudDownloadOutlined />}>Baixar documento</Button>
              </Dropdown>
              <Button
                type='primary'
                danger
                icon={<CloseCircleOutlined />}
                onClick={openModalCancel}
                hidden={!shouldShowButton}
                disabled={isButtonDisabled}
              >
                Cancelar documento
              </Button>
            </Space>
          </Col>
        </Row>
        <Row style={{ paddingBottom: '50px' }}>
          <Col span={24}>
            <Title level={3} style={{ marginRight: 20 }}>{documentFile.description}</Title>
            <small><b>Criado em {moment(documentFile.createdAt).format('DD/MM/YYYY HH:mm')}</b></small><br />
            <small><b>Arquivo:</b> {documentFile.fileName}</small><br /><br />

            {documentFile.status === 0 && (<Tag color='orange' icon={<ClockCircleOutlined />}>Pendente</Tag>)}
            {documentFile.status === 1 && (<Tag color='green' icon={<CheckCircleOutlined />}>Finalizado em {moment(documentFile.digitallySignedAt).format('DD/MM/YYYY HH:mm')}</Tag>)}
            {documentFile.status === 2 && (<Tag color='yellow' icon={<CloseSquareOutlined />}>Anulado</Tag>)}
            {documentFile.status === 3 && (<Tag color='red' icon={<CloseCircleOutlined />}>Cancelado em {moment(documentFile.cancelledAt).format('DD/MM/YYYY HH:mm')}</Tag>)}
            {documentFile.status === 4 && (<Tag color='default' icon={<IssuesCloseOutlined />}>Expirado</Tag>)}
            {documentFile.status === 5 && (<Tag color='blue' icon={<ClockCircleOutlined />}>Assinatura Digital Pendente</Tag>)}<br /><br />

            {documentFile.cancelledAt && (<small><b>Motivo do cancelamento:</b> <br />{documentFile.cancellationReason}</small>)}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4}>Lista de signatários</Title >
            <small>{`${signerRemaining} de ${signerTotal} assinatura(s)`}</small>
            {participants.map((participant) => (
              <Card key={participant.name} bordered={false} style={{ marginTop: 16, marginBottom: 16 }}>
                <Row>
                  <Col span={24}>
                    <Title level={4}>{participant.name}</Title >
                    {(participant.hasSigned ? (<Tag color="green" icon={<CheckCircleOutlined />}>Assinado em {moment(participant.signature?.signedAt).format('DD/MM/YYYY HH:mm')}</Tag>) : (<Tag color="orange" icon={<ClockCircleOutlined />}>Assinatura Pendente</Tag>))} <br />
                    {participant.hasSigned && (
                      <>
                        <small>CPF: {participant.signature?.cpf}</small><br />
                        <small>IP: {participant.signature?.ip}</small><br />
                        <small>Assinado em: {moment(participant.signature?.signedAt).format('DD/MM/YYYY HH:mm')}</small><br />
                      </>
                    )}
                    {!participant.hasSigned && shouldShowButton && (
                      <Space.Compact style={{ width: 600, marginTop: 16 }}>
                        <Input type='text' value={participant.signingUrl} />
                        <Tooltip title='Copiar link'>
                          <Button type="default" onClick={() => handleCopy(participant.signingUrl)} icon={<CopyOutlined />}>Copiar</Button>
                        </Tooltip>
                      </Space.Compact>
                    )}
                  </Col>
                </Row>
              </Card>
            ))}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4}>Lista de observadores</Title >
            {observers.map((participant) => (
              <Card key={participant.name} bordered={false} style={{ marginTop: 16, marginBottom: 16 }}>
                <Row>
                  <Col span={24}>
                    <Title level={4}>{participant.name}</Title >
                  </Col>
                </Row>
              </Card>
            ))}
          </Col>
        </Row>
      </Spin>

      <Modal
        open={isModalCancelOpen}
        title='Cancelar documento'
        onCancel={() => setIsModalCancelOpen(false)}
        footer={[
        ]}
      >
        <p>O documento será cancelado imediatamente. Esta opção só é possível, pois nenhum usuário assinou e o status do documento está pendente.</p>
        <Form
          layout="vertical"
          form={formCancel}
          hideRequiredMark
          onFinish={handleCancelarDocumento}
          initialValues={{
            motivo: ''
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="reason"
                label="Motivo do cancelamento"
                rules={[
                  { required: true, message: 'Motivo do cancelamento é um campo obrigatório' },
                  { min: 30, message: 'É necessário um mínimo de 30 caracteres' }
                ]}
              >
                <TextArea rows={4} showCount maxLength={256} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button onClick={() => setIsModalCancelOpen(false)} style={{ marginRight: 8 }} htmlType="button">
                Fechar sem cancelar
              </Button>
              <Button type="primary" danger htmlType="submit" loading={isLoading}>
                Cancelar documento
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={isModalUpdateTitleOpen}
        title='Alterar titulo do documento'
        onCancel={() => setIsModalUpdateTitleOpen(false)}
        footer={[
        ]}
      >
        <Row gutter={16} style={{ paddingTop: 20, paddingBottom: 50 }}>
          <Col span={24}>
            <Paragraph>Novo nome para o documento</Paragraph>
            <Input onChange={handleChange} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button onClick={() => setIsModalUpdateTitleOpen(false)} style={{ marginRight: 8 }} htmlType="button">
              Cancelar
            </Button>
            <Button type="primary" onClick={handleOk} loading={isLoading}>
              Confirmar
            </Button>
          </Col>
        </Row>
      </Modal>
    </Content>
  );
};

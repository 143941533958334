/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Card, Col, ConfigProvider, Empty, Form, Input, Pagination, Row, Skeleton, Space, Table, Tag, Tooltip, Typography, notification, DatePicker, Select, Drawer, Badge } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  PlusOutlined,
  EyeOutlined,
  SearchOutlined,
  FilterOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FieldTimeOutlined,
  EditOutlined
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { DocumentsService, IDocument } from '../../shared/services/api';
import moment, { lang } from 'moment';
import Meta from 'antd/es/card/Meta';
import { Content } from 'antd/es/layout/layout';
import { Dayjs } from 'dayjs';
import { readTokenPayload } from '../../shared/utils/tokenHelper';
import { UsersService } from '../../shared/services/api/UsersService';
import { usePermission } from '../../shared/contexts/PermissionContext';

const { Title } = Typography;
const { RangePicker } = DatePicker;

interface ISearch {
  description?: string;
  documentStatus?: string;
  createdAtInterval?: [Dayjs, Dayjs]
}

export const Documents = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { hasPermission } = usePermission();

  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<IDocument[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limiteLinhas, setLimiteLinhas] = useState(10);
  const [search, setSearch] = useState<ISearch>({} as ISearch);

  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  const [pendentes, setPendentes] = useState<number>(0);
  const [assinados, setAssinados] = useState<number>(0);
  const [cancelados, setCancelados] = useState<number>(0);
  const [expirados, setExpirados] = useState<number>(0);

  const [filterActive, setFilterActive] = useState<number>(0);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);

    const limiteLinhas: number = parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS || '', 10);
    setLimiteLinhas(limiteLinhas);

    DocumentsService.getAll(currentPage, limiteLinhas, search.description, search.documentStatus, (search.createdAtInterval ? `${search.createdAtInterval[0].format('YYYY-MM-DD')},${search.createdAtInterval[1].format('YYYY-MM-DD')}` : ''), true)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.warning({
            message: result.message
          });
        } else {
          setDataTable(result.data);
          setTotalCount(result.total);

          // Object.entries(result.totalByStatus).forEach(([, { documentStatus, total }]) => {
          //   if (documentStatus === 0) {
          //     setPendentes(pendentes + total);
          //   } else if (documentStatus === 1) {
          //     setAssinados(assinados + total);
          //   } else if (documentStatus === 2) {
          //     setCancelados(cancelados + total);
          //   } else if (documentStatus === 3) {
          //     setCancelados(cancelados + total);
          //   } else if (documentStatus === 4) {
          //     setExpirados(expirados + total);
          //   } else if (documentStatus === 5) {
          //     setPendentes(pendentes + total);
          //   }
          // });
        }
      });
  }, [currentPage, search]);

  const newDocument = () => {
    navigate('/documentos/novo-documento');
  };

  const viewDocument = async (key: string) => {
    navigate(`/documentos/detalhe/${key}`);
  };

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = () => {
    setSearch(form.getFieldsValue());
    onClose();
  };

  const handleClearSearch = () => {
    form.resetFields();
    setSearch({});
    onClose();
  };

  useEffect(() => {
    let index = 0;

    if (search.createdAtInterval !== undefined && search.createdAtInterval !== null) {
      index++;
    }

    if (search.description !== undefined && search.description !== null) {
      index++;
    }

    if (search.documentStatus !== undefined && search.documentStatus !== null) {
      index++;
    }

    setFilterActive(index);

  }, [search]);

  // useEffect(() => {
  //   const payloadToken = readTokenPayload();
  //   if (payloadToken?.oid) {
  //     UsersService.getById(payloadToken?.oid)
  //       .then((result) => {
  //         setIsLoading(false);

  //         if (result instanceof Error) {
  //           notification.error({
  //             message: result.message
  //           });
  //         } else {
  //           if (result.email === '') {
  //             updateUserEmail(result.id, payloadToken?.emails[0]);
  //           }
  //         }
  //       });
  //   }
  // }, []);

  // const updateUserEmail = (id: number, email: string) => {
  //   UsersService.update(id, email)
  //     .then((result) => {
  //       setIsLoading(false);

  //       if (result instanceof Error) {
  //         notification.error({
  //           message: result.message
  //         });
  //       } else {
  //         console.log(result);
  //       }
  //     });
  // };

  const columns: ColumnsType<IDocument> = [
    {
      title: 'Documento',
      dataIndex: 'description',
      key: 'description',
      render: (_, { description, fileName, participants, deadline }) => {
        const total = participants.filter(x => x.isSigner).length;
        const remaining = participants.filter(x => x.isSigner && x.hasSigned).length;

        return (
          <>
            <Title level={4}>{description}</Title>
            <small>{remaining} de {total} Assinaturas</small><br />
            <small>{fileName}</small><br />
            {deadline && (<><small>Prazo final: {moment(deadline).format('DD/MM/YYYY')}</small></>)}
          </>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      render: (_, { status }) => {
        if (status === 0) {
          return <Tag color="orange">Pendente</Tag>;
        } else if (status === 1) {
          return <Tag color="green">Finalizado</Tag>;
        } else if (status === 2) {
          return <Tag color="yellow">Anulado</Tag>;
        } else if (status === 3) {
          return <Tag color="red">Cancelado</Tag>;
        } else if (status === 4) {
          return <Tag color="default">Expirado</Tag>;
        } else if (status === 5) {
          return <Tag color="blue">Assinatura Digital Pendente</Tag>;
        }
      }
    },
    {
      title: 'Data Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['lg'],
      render: (_, { createdAt }) => {
        return moment(createdAt).format('DD/MM/YYYY HH:mm');
      }
    },
    {
      key: 'actions',
      render: (_, { key }) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip title="Ver documento">
            <Button type="text" shape="circle" icon={<EyeOutlined />} onClick={() => viewDocument(key)} />
          </Tooltip>
        </Space>
      ),
    }
  ];

  const customizeRenderEmpty = () => (
    <Empty
      imageStyle={{ height: 60 }}
      description={
        <span>
          Nenhum registro encontrado.
        </span>
      }
    >
      <Button type="primary" icon={<PlusOutlined />} onClick={() => newDocument()} disabled={!hasPermission(100)}>
        Novo documento
      </Button>
    </Empty>
  );

  return (
    <Content>
      <Row>
        <Col sm={14} xs={24}>
          <Title level={3}>Documentos</Title >
        </Col>
        <Col sm={10} xs={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Space>
            <Button
              type="primary"
              onClick={() => newDocument()}
              icon={<PlusOutlined />}
              loading={isLoading}
              disabled={!hasPermission(100)}
            >
              Novo documento
            </Button>

            <Space size="middle">
              <Badge count={filterActive}>
                <Button type="default" onClick={showDrawer} icon={<FilterOutlined />} loading={isLoading}>
                  Filtros
                </Button>
              </Badge>
            </Space>
          </Space>
        </Col>
      </Row>
      <Row
        style={{ marginTop: '30px' }}
      >
        <Col span={24}>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Table
              columns={columns}
              dataSource={dataTable}
              rowKey={(record) => record.key}
              loading={isLoading}
              size="small"
              pagination={false}
            />
            <Pagination
              pageSize={limiteLinhas}
              style={{ float: 'right', margin: '5px' }}
              current={currentPage}
              onChange={onChangePage}
              total={totalCount}
              showTotal={total => `Total ${total} registro(s)`}
            />
          </ConfigProvider>
        </Col>
      </Row>

      <Drawer
        title="Filtrar"
        onClose={onClose}
        open={open}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={handleSearch}
        >
          <Form.Item
            name="createdAtInterval"
            label="Período"
          >
            <RangePicker format='DD/MM/YYYY' />
          </Form.Item>
          <Form.Item
            name="description"
            label="Documento"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="documentStatus"
            label="Status"
          >
            <Select
              defaultValue=''
              style={{ width: 120 }}
              allowClear
              options={[
                { value: '0', label: 'Pendente' },
                { value: '1', label: 'Finalizado' },
                // { value: '2', label: 'Anulado' },
                { value: '3', label: 'Cancelado' },
                { value: '4', label: 'Expirado' },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SearchOutlined />} loading={isLoading}>
                Buscar
              </Button>
              <Button type="dashed" onClick={handleClearSearch} loading={isLoading}>
                Limpar filtros
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>

    </Content>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Immutable from 'immutable';
import { useEffect, useRef, useState } from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IDrawAreaProps {
  children: React.ReactNode;
  cursor: string;
  page: number;
  flag: string;
  getBounds: (obj: any) => void;
  changeFlag: () => void;
  buttonType: string;
  resetButtonType: () => void;
  getPaths: (el: any) => void;
}

interface IDrawingProps {
  lines: any[];
  page: number;
}

interface IDrawingLineProps {
  line: any;
  page: number;
}

export const DrawArea: React.FC<IDrawAreaProps> = ({ children, cursor, page, flag, getBounds, changeFlag, buttonType, resetButtonType, getPaths }) => {
  const drawAreaEl = useRef<any>(null);
  const [isCrosshair, setIsCrosshair] = useState(false);
  const [lines, setLines] = useState<any[]>([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [redoEl, setRedoEl] = useState<any[]>([]);

  useEffect(() => {
    const drawArea = document.getElementById('drawArea');

    if (drawArea) {
      drawArea.addEventListener('mouseup', handleMouseUp);

      getBounds({
        x: drawAreaEl.current.getBoundingClientRect().left,
        y: drawAreaEl.current.getBoundingClientRect().bottom,
      });

      return () => {
        drawArea.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, []);

  useEffect(() => {
    if (flag === 'undo') {
      setRedoEl(arr => [...arr, lines.pop()]);
      setLines(lines);
    }
    if (flag === 'redo') {
      setLines(lines => [...lines, redoEl.pop()]);
    }
    changeFlag();
  }, [flag]);

  useEffect(() => {
    if (buttonType === 'draw') {
      addMouseDown();
      resetButtonType();
    }
  }, [buttonType]);

  useEffect(() => {
    if (isDrawing === false && lines.length) {
      getPaths(lines[lines.length - 1]);
    }
  }, [isDrawing]);

  const handleMouseUp = () => {
    setIsCrosshair(false);
    setIsDrawing(false);
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing) {
      return;
    }
    const point = relativeCoordinatesForEvent(e);
    const last = lines.pop();
    last.arr.push(point);
    setLines(prevlines => [...prevlines, last]);
  };

  const handleMouseDown = (e: any) => {

    if (e.button !== 0) {
      return;
    }
    const point = relativeCoordinatesForEvent(e);
    const obj = {
      arr: [point],
      page: page,
      type: 'freehand',
    };
    setLines(prevlines => [...prevlines, obj]);
    setIsDrawing(true);
  };

  const relativeCoordinatesForEvent = (e: any) => {
    const boundingRect = drawAreaEl.current.getBoundingClientRect();
    return Immutable.Map({
      x: e.clientX - boundingRect.left,
      y: e.clientY - boundingRect.top,
    });
  };

  const addMouseDown = () => {
    setIsCrosshair(true);
    const drawArea = document.getElementById('drawArea');

    if (drawArea) {
      drawArea.addEventListener('mousedown', handleMouseDown, { once: true });
    }
  };

  const Drawing: React.FC<IDrawingProps> = ({ lines, page }) => {
    return (
      <svg className="drawing" style={{ zIndex: 10 }}>
        {lines.map((line, index) => (
          <DrawingLine key={index} line={line} page={page} />
        ))}
      </svg>
    );
  };

  const DrawingLine: React.FC<IDrawingLineProps> = ({ line, page }) => {
    const pathData = 'M ' + line.arr.map((p: { get: (arg0: string) => any; }) => {
      return `${p.get('x')},${p.get('y')}`;
    }).join(' L ');

    if (line.page === page) {
      return <path className="path" d={pathData} />;
    }

    return null;
  };

  return (
    <>
      <div
        id="drawArea"
        ref={drawAreaEl}
        style={isCrosshair ? { cursor: 'crosshair' } : { cursor: cursor }}
        onMouseMove={handleMouseMove}
      >
        {children}
        <Drawing lines={lines} page={page} />
      </div>
    </>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Popconfirm, Result, Row, Select, Space, Spin, Switch, Table, Tabs, Tooltip, Typography, Upload, UploadFile, UploadProps, message, notification } from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  SwapOutlined,
  CheckOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  ArrowLeftOutlined,
  CheckCircleOutlined,
  EyeOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { ContactsService, DocumentsService, IContact, IGroup, ISigningFlow, IUpload, IUploadResp, SigningFlowsServices } from '../../shared/services/api';
import { RcFile } from 'antd/es/upload';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { usePermission } from '../../shared/contexts/PermissionContext';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

export const NewDocument = () => {
  const { hasPermission } = usePermission();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentSend, setIsDocumentSend] = useState(false);
  const [titleDocument, setTitleDocument] = useState<string>('');

  // Fluxos
  const [signingFlowId, setSigningFlowId] = useState<number>();
  const [signingFlow, setSigningFlow] = useState<ISigningFlow[]>([]);
  const [isModalGroup, setIsModalGroup] = useState(false);
  const [tableParamsGroup, setTableParamsGroup] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  // Contatos
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [isModalContact, setIsModalContact] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  // Upload Document
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadResp, setUploadResp] = useState<IUploadResp[]>([]);
  const [deadLine, setDeadLine] = useState<Dayjs | null>(null);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().endOf('day');
  };

  // Inform Participant
  const [form] = Form.useForm();

  //#region ######### Initialize
  const handleDatePickerChange = (date: Dayjs | null, dateString: string | string[]) => {
    setDeadLine(date);
  };
  //#endregion ######### Initialize

  //#region ######### Actions
  const handleSendDocument = async () => {
    if (titleDocument === '') {
      Modal.warning({
        title: 'Atenção',
        content: 'Informe o titulo do documento.'
      });
      return;
    }

    if (fileList.length <= 0) {
      Modal.warning({
        title: 'Atenção',
        content: 'Selecione o documento.'
      });

      return;
    }

    const signatories = form.getFieldsValue();
    const filteredSignatories = signatories.signatories.filter((obj: { signingPriority: number; }) => obj.signingPriority !== 0);
    if (filteredSignatories.length <= 0) {
      Modal.warning({
        title: 'Atenção',
        content: 'Informe no mínimo 1 signatário.'
      });

      return;
    }

    form.validateFields()
      .then((values) => {
        uploadResp.map((value) => {
          DocumentsService
            .create({
              key: value.key,
              description: titleDocument,
              deadline: (deadLine !== undefined ? deadLine?.format('YYYY-MM-DD') : undefined),
              participants: values.signatories,
              signingFlowId: signingFlowId ?? undefined
            })
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                notification.error({
                  message: 'Erro',
                  description: result.message
                });
              } else {
                setIsDocumentSend(true);
              }
            });
        });
      })
      .catch((error) => {
        Modal.warning({
          title: 'Atenção',
          content: error.errorFields[0].errors[0]
        });
        return;
      });
  };

  const handleAddSignaturesMassive = (array: any[], getFields: boolean) => {
    form.resetFields();
    const currentSignatories: any[] = [];

    const newSignatories = array.map(item => {
      return {
        signingPriority: getFields ? item.signingPriority : '',
        name: getFields ? item.name : '',
        email: getFields ? item.email : '',
        isSigner: true,
        canRefuse: false
      };
    });

    const updatedSignatories = [
      ...currentSignatories,
      ...newSignatories.map((participant, index) => ({
        ...participant,
        signingPriority: currentSignatories.length + index + 1,
      })),
    ];

    form.setFieldsValue({ signatories: updatedSignatories });
  };
  //#endregion ######### Actions

  //#region ######### Signatories
  const handleAddSignature = () => {
    let sPriority = 0;
    const signatories = form.getFieldsValue();
    const filteredSignatories = signatories.signatories.filter((obj: { signingPriority: number; }) => obj.signingPriority !== 0);

    if (filteredSignatories.length > 0) {
      const ultimoObjeto = filteredSignatories[filteredSignatories.length - 1];
      sPriority = Number(ultimoObjeto.signingPriority) + 1;
    } else {
      sPriority = 1;
    }

    form.setFieldsValue({
      signatories: [...form.getFieldValue('signatories'), {
        signingPriority: sPriority,
        name: '',
        email: '',
        signingRole: 1,
        isSigner: true,
        canRefuse: false
      }],
    });
  };

  const handleAddObserver = () => {
    form.setFieldsValue({
      signatories: [...form.getFieldValue('signatories'), {
        signingPriority: 0,
        name: '',
        email: '',
        signingRole: 1,
        isSigner: false,
        canRefuse: false
      }],
    });
  };
  //#endregion ######### Signatories

  //#region ######### Groups
  useEffect(() => {
    SigningFlowsServices.getAll(tableParamsGroup.pagination?.current, tableParamsGroup.pagination?.pageSize)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.warning({
            message: result.message
          });
        } else {
          setSigningFlow(result.data);

          setTableParamsGroup({
            ...tableParamsGroup,
            pagination: {
              ...tableParamsGroup.pagination,
              total: result.total,
            },
          });
        }
      });
  }, [JSON.stringify(tableParamsGroup)]);

  const handleGroupChange = (pagination: TablePaginationConfig) => {
    setTableParamsGroup({
      pagination,
    });

    if (pagination.pageSize !== tableParamsGroup.pagination?.pageSize) {
      setSigningFlow([]);
    }
  };

  const handleSelectGroup = (group: ISigningFlow) => {
    form.resetFields();
    setSigningFlowId(group.id);
    handleAddSignaturesMassive(group.participants, true);

    setIsModalGroup(false);
  };

  const handleRemoveSigningFlow = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Atenção',
      content: 'Deseja realmente remover o fluxo selecionado?',
      onOk() { removeSigningFlow(); },
      onCancel() { Modal.destroyAll(); },
      cancelText: 'Cancelar',
      okText: 'Sim, remover',
    });
  };

  const removeSigningFlow = () => {
    form.resetFields();
    setSigningFlowId(undefined);
  };

  const columnsGroup: ColumnsType<ISigningFlow> = [
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      key: 'actions',
      render: (record: ISigningFlow) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip
            title='Selecionar fluxo'
          >
            <Button type="text" shape="circle" icon={<CheckCircleOutlined />} onClick={() => handleSelectGroup(record)} />
          </Tooltip>
        </Space>
      ),
    }
  ];
  //#endregion ######### Groups

  //#region ######### Contacts
  useEffect(() => {
    ContactsService.getAll(tableParams.pagination?.current, tableParams.pagination?.pageSize)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setContacts(result.data);

          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: result.total,
            },
          });
        }
      });
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
  ) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setContacts([]);
    }
  };

  const handleSelectContact = (contact: IContact) => {
    const currentSignatories = form.getFieldValue('signatories') || [];
    const newSignatory = {
      name: contact.name,
      email: contact.email,
      signingRole: 1,
      isSigner: true,
      canRefuse: false,
    };

    const updatedSignatories = [
      ...currentSignatories,
      {
        ...newSignatory,
        signingPriority: currentSignatories.length + 1,
      },
    ];

    form.setFieldsValue({
      signatories: updatedSignatories,
    });

    setIsModalContact(false);
  };

  const columnsContacts: ColumnsType<IContact> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      key: 'actions',
      render: (record: IContact) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip
            title='Selecionar grupo'
          >
            <Button type="text" shape="circle" icon={<CheckCircleOutlined />} onClick={() => handleSelectContact(record)} />
          </Tooltip>
        </Space>
      ),
    }
  ];

  const columnsContactsConsult: ColumnsType<IContact> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
  ];
  //#endregion ######### Contacts

  //#region ######### Documents
  const setUpload = async (url: string) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as RcFile);
    });
    // You can use any AJAX library you like
    fetch(url, {
      method: 'PUT',
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-blob-content-type': 'application/octet-stream'
      },
      body: formData,
    })
      .then((res) => { })
      .then(() => {
        notification.success({
          message: 'Arquivo selecionado com sucesso!',
        });
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Falha ao selecionar o arquivo.'
        });
      });
  };

  useEffect(() => {
    if (fileList.length > 0) {
      setIsLoading(true);
      fileList.forEach((file) => {
        DocumentsService
          .uploads({ fileName: (file as RcFile).name, size: (file as RcFile).size } as IUpload)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              notification.error({
                message: result.message
              });
            } else {
              setUploadResp([...uploadResp, result]);
              setUpload(result.url);
            }
          });

        setIsLoading(false);
      });
    }
  }, [fileList]);

  const getFileTemplate = async (url: string) => {
    const response = await fetch(url);

    // Verifica se conseguiu baixar o arquivo.
    if (!response.ok) {
      throw new Error('Falha ao obter o arquivo PDF');
    }

    // Pega o blob
    const pdfBlob = await response.blob();

    // Obtem os dados do arquivo
    const contentDisposition = response.headers.get('content-disposition');
    let fileName = 'unknown.pdf';
    if (contentDisposition) {
      const matches = contentDisposition.match(/filename="(.+)"$/);
      if (matches && matches.length > 1) {
        fileName = matches[1];
      }
    }

    // Cria um objeto File a partir do blob
    const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf', lastModified: Date.now() });

    setFileList([...fileList, (pdfFile as RcFile)]);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setUploadResp([]);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        notification.warning({
          message: 'Só é possível selecionar arquivos PDF'
        });
        return false;
      }

      if (fileList.length > 0) {
        notification.info({
          message: 'Já existe um arquivo selecionado agurdando para ser enviado'
        });
        return false;
      }

      setFileList([...fileList, file]);

      if (titleDocument === '') {
        setTitleDocument(file.name);
      }

      return false;
    },
    fileList,
  };

  if (isDocumentSend) {
    return (
      <Result
        status="success"
        title="O documento foi enviado com sucesso."
        subTitle="Aguardando assinaturas."
        extra={
          <Button type="primary" key="console" onClick={() => navigate('/documentos')} >
            Ver documentos
          </Button >
        }
      />
    );
  }
  //#endregion ######### Documents

  return (
    <>
      <Spin spinning={isLoading}>
        <Row style={{ paddingBottom: '16px' }}>
          <Col span={4}>
            <Button type="text" onClick={() => navigate('/documentos')} icon={<ArrowLeftOutlined />}>Voltar para documentos</Button>
          </Col>
          <Col span={20} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Space>
              {/* <Button
                type="default"
                onClick={() => setIsModalTemplate(true)}
                icon={<FileTextOutlined />}
                loading={isLoading}
              >
                Selecionar modelo
              </Button> */}
              <Button
                type="primary"
                onClick={handleSendDocument}
                icon={<CheckOutlined />}
                loading={isLoading}
              >
                Enviar documento
              </Button>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={3}>Novo documento</Title>
            <Input
              value={titleDocument}
              onChange={(e) => setTitleDocument(e.target.value)}
              required
              placeholder='Titulo do documento...'
              bordered={false}
              className="custom-input"
              style={{
                fontSize: 25,
                fontWeight: 'bold'
              }}
            />
          </Col>
        </Row>
        <Tabs defaultActiveKey='1'>
          <TabPane tab="Documento" key="1">
            <Row
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '30px'
              }}
            >
              <Col span={24}>
                <Title level={5}>Selecione o documento a ser assinado</Title >
              </Col>
              <Col span={24}>
                <Upload.Dragger
                  listType="picture" {...props} disabled={isLoading}
                >
                  <div style={{ padding: '10px' }}>
                    <p className="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
                    <p className="ant-upload-hint">Suporte para upload único.</p>
                  </div>
                </Upload.Dragger>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Signatários" key="2">
            <Row
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '30px'
              }}
            >
              <Col span={24} style={{ justifyContent: 'flex-start', display: 'flex' }}>
                <Space>
                  <Button
                    type="default"
                    onClick={handleAddSignature}
                    icon={<PlusOutlined />}
                    loading={isLoading}
                    hidden={signingFlowId !== undefined}
                  >
                    Adicionar signatário
                  </Button>
                  <Button
                    type="default"
                    onClick={handleAddObserver}
                    icon={<PlusOutlined />}
                    loading={isLoading}
                    hidden={signingFlowId !== undefined}
                  >
                    Adicionar observador
                  </Button>
                  <Button
                    type="default"
                    onClick={() => setIsModalContact(true)}
                    icon={<UserOutlined />}
                    loading={isLoading}
                    hidden={!hasPermission(401) || signingFlowId !== undefined}
                  >
                    Buscar contato
                  </Button>
                  <Button
                    type="default"
                    onClick={() => setIsModalGroup(true)}
                    icon={<SwapOutlined />}
                    loading={isLoading}
                    hidden={!hasPermission(501) || signingFlowId !== undefined}
                  >
                    Selecionar fluxo
                  </Button>
                  <Button
                    type="default"
                    danger
                    onClick={handleRemoveSigningFlow}
                    icon={<SwapOutlined />}
                    loading={isLoading}
                    hidden={!hasPermission(501) || signingFlowId === undefined}
                  >
                    Remover fluxo
                  </Button>
                  <Tooltip placement="right" title="A ordem de envio seguirá os números atribuídos, do menor para o maior. Caso opte por informar um número igual para todos os signatários, os e-mails serão enviados para todos os assinantes sem uma ordem específica." >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              </Col>
              <Col span={24} style={{ marginTop: 16 }}>
                <Form
                  form={form}
                  labelCol={{ span: 6 }}
                  name="dynamic_form_complex"
                  style={{ maxWidth: 600 }}
                  autoComplete="off"
                  initialValues={{ signatories: [] }}
                >
                  <Form.List
                    name="signatories"
                    rules={[
                      {
                        validator: async (_, signatories) => {
                          if (!signatories || signatories.length < 1) {
                            return Promise.reject(new Error('Informe o(s) signatário(s)'));
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { remove }, { errors }) => (
                      <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                        {fields.map((field) => (
                          <Card
                            size="small"
                            title={!form.getFieldValue(['signatories', field.name, 'isSigner']) ? 'Observador' : 'Signatário'}
                            key={field.key}
                            extra={
                              <Button
                                type='text'
                                danger
                                onClick={() => {
                                  remove(field.name);
                                }}
                                icon={<DeleteOutlined />}
                                hidden={signingFlowId !== undefined}
                              >
                                Remover
                              </Button>
                            }
                          >
                            <Form.Item
                              label="Ordem de assinatura"
                              name={[field.name, 'signingPriority']}
                            >
                              <Select
                                style={{ width: 80 }}
                                onChange={() => { }}
                                options={[
                                  // { value: '0', label: '0' },
                                  { value: '1', label: '1' },
                                  { value: '2', label: '2' },
                                  { value: '3', label: '3' },
                                  { value: '4', label: '4' },
                                  { value: '5', label: '5' },
                                  { value: '6', label: '6' },
                                  { value: '7', label: '7' },
                                  { value: '8', label: '8' },
                                  { value: '9', label: '9' },
                                ]}
                                disabled={signingFlowId !== undefined || !form.getFieldValue(['signatories', field.name, 'isSigner'])}
                              />
                            </Form.Item>

                            <Form.Item
                              label="Nome do signatário"
                              name={[field.name, 'name']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[{ required: true, message: `Nome do ${!form.getFieldValue(['signatories', field.name, 'isSigner']) ? 'observador' : 'signatário'} é um campo obrigatório` }]}
                            >
                              <Input type='text' />
                            </Form.Item>

                            <Form.Item
                              label="E-mail do signatário"
                              name={[field.name, 'email']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={
                                [
                                  { required: true, message: `E-mail do ${!form.getFieldValue(['signatories', field.name, 'isSigner']) ? 'observador' : 'signatário'} é um campo obrigatório` },
                                  { type: 'email', message: `O e-mail informado no ${!form.getFieldValue(['signatories', field.name, 'isSigner']) ? 'observador' : 'signatário'} é inválido` }
                                ]
                              }
                            >
                              <Input type='email' />
                            </Form.Item>
                          </Card>
                        ))}

                        <Form.ErrorList errors={errors} />
                      </div>
                    )}
                  </Form.List>
                </Form>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Configurações adicionais" key="4">
            <Row>
              <Col span={24} style={{ marginRight: 16, marginBottom: 16 }}>
                <Paragraph style={{ margin: 0 }}>Data validade</Paragraph>
                <DatePicker format='DD/MM/YYYY' disabledDate={disabledDate} onChange={handleDatePickerChange} />
              </Col>
            </Row>
          </TabPane>
        </Tabs>

        <Modal
          open={isModalGroup}
          width={800}
          onCancel={() => setIsModalGroup(false)}
          title='Selecionar fluxo'
          footer={[
          ]}
        >
          <Table
            columns={columnsGroup}
            rowKey={(record) => record.id}
            dataSource={signingFlow}
            loading={isLoading}
            size="small"
            pagination={tableParamsGroup.pagination}
            onChange={handleGroupChange}
          />
        </Modal>

        <Modal
          open={isModalContact}
          width={800}
          onCancel={() => setIsModalContact(false)}
          title='Selecionar contato'
          footer={[
          ]}
        >
          <Table
            columns={columnsContacts}
            rowKey={(record) => record.id}
            dataSource={contacts}
            loading={isLoading}
            size="small"
            pagination={tableParams.pagination}
            onChange={handleTableChange}
          />
        </Modal>

      </Spin>
    </>
  );
};

import { Api } from './axios-config';
import { setHeaderAuthorization } from '../../utils/apiHelper';
import axios from 'axios';

export interface IUser {
  id: number;
  name: string;
  email: string;
  roles: IRole[];
  createdAt: Date;
  loginTime: Date;
}

// export interface IMe {
//   id: number;
//   name: string;
//   email: string;
//   permissions: number[];
//   roles: string[];
// }

export interface IRole {
  id: number;
  name: string;
}

export interface IPermission {
  id: number;
  description: string;
}

export interface IMe {
  id: number;
  name: string;
  email: string;
  roles: IRole[];
  permissions: IPermission[];
}

type TUserCount = {
  currentPage: number;
  data: IUser[];
  perPage: number;
  total: number;
}

const getAll = async (currentPage: number = 1, perPage: number = 5, name?: string, participating?: boolean): Promise<TUserCount | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/users?currentPage=${currentPage}&perPage=${perPage}&name=${name}&participating=${participating}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const get = async (id: number): Promise<IUser | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/users/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao buscar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao buscar o registro.');
  }
};

const getById = async (key: string): Promise<IUser | Error> => {
  setHeaderAuthorization();

  try {
    const { data } = await Api.get(`/users/${key}`);
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const update = async (id: number, email: string): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.put(`/users/${id}`, { email });
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const me = async (role: string): Promise<IMe | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.post<IMe>('/users/me', { role });

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const userInfo = async (): Promise<any | Error> => {
  try {
    const accessToken = await sessionStorage.getItem('accessToken') || undefined;

    const { data } = await axios({
      method: 'get',
      url: 'https://sso.engmaxsign.com.br/realms/engmaxsign-dev/protocol/openid-connect/userinfo',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

export const UsersService = {
  getAll,
  getById,
  update,
  me,
  get,
  userInfo
};

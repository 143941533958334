import { Api } from './axios-config';
import { setHeaderAuthorization } from '../../utils/apiHelper';
import { IUser } from './UsersService';

export interface ISigningFlow {
  id: number;
  description: string;
  participants: ISigningFlowParticipant[];
  associatedUsers?: IUser[];
  userId?: number;
}

export interface ISigningFlowParticipant {
  Name: string;
  Email: string;
  IsSigner: boolean;
  SigningPriority: number;
}

export interface ISigningFlowUpdate {
  id: number;
  description: string;
  participants: ISigningFlowParticipant[];
  associatedUsers?: number[];
}

type TData = {
  currentPage: number;
  data: ISigningFlow[];
  perPage: number;
  total: number;
}

const getAll = async (currentPage: number = 1, perPage: number = 5): Promise<TData | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/SigningFlow?currentPage=${currentPage}&perPage=${perPage}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: number): Promise<ISigningFlow | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/SigningFlow/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const create = async (dados: Omit<ISigningFlow, 'id'>): Promise<number | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.post<ISigningFlow>('/SigningFlow', dados);

    if (data) {
      return data.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const update = async (id: number, dados: ISigningFlowUpdate): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.put(`/SigningFlow/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const exclude = async (id: number): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.delete(`/SigningFlow/${id}`);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const SigningFlowsServices = {
  getAll,
  getById,
  create,
  update,
  exclude,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, ConfigProvider, Divider, Empty, Form, Input, Modal, Pagination, Popconfirm, Row, Select, Space, Table, TableColumnsType, Tooltip, Typography, notification } from 'antd';
import {
  PlusOutlined,
  FormOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { ISigningFlow, SigningFlowsServices } from '../../shared/services/api';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { usePermission } from '../../shared/contexts/PermissionContext';
import { useDebounce } from '../../shared/hooks';

const { Title } = Typography;

export const Groups = () => {
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const { debounce } = useDebounce();

  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<ISigningFlow[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limiteLinhas, setLimiteLinhas] = useState(10);

  const getData = useCallback(() => {
    setIsLoading(true);

    const limiteLinhas: number = parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS || '', 10);
    setLimiteLinhas(limiteLinhas);
    debounce(() => {
      SigningFlowsServices.getAll(currentPage, limiteLinhas)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            setDataTable(result.data);
            setTotalCount(result.total);
          }
        });
    });
  }, [currentPage]);

  const createFlow = () => {
    navigate('/fluxos/novo-fluxo');
  };

  const handleRemove = async (id: number) => {
    setIsLoading(true);
    SigningFlowsServices.exclude(id)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          notification.info({
            message: 'Fluxo excluído com sucesso!'
          });
          getData();
        }
      });
  };

  const hidrateFormWithValues = async (data: ISigningFlow) => {
    navigate(`/fluxos/${data.id}`);
  };

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnsType<ISigningFlow> = [
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      key: 'actions',
      render: (record: ISigningFlow) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<FormOutlined />} onClick={() => hidrateFormWithValues(record)} disabled={!hasPermission(502)} />
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir este fluxo?"
            onConfirm={() => handleRemove(record.id)}
            okText="Sim"
            cancelText="Não"
            placement="left"
          >
            <Button type="text" shape="circle" icon={<DeleteOutlined />} disabled={!hasPermission(503)} />
          </Popconfirm>
        </Space>
      ),
    }
  ];

  const customizeRenderEmpty = () => (
    <Empty
      imageStyle={{ height: 60 }}
      description={
        <span>
          Nenhum registro encontrado.
        </span>
      }
    >
      <Button type="primary" icon={<PlusOutlined />} onClick={createFlow} loading={isLoading} disabled={!hasPermission(500)}>
        Novo fluxo
      </Button>
    </Empty>
  );

  return (
    <>
      <Row>
        <Col span={14}>
          <Title level={3}>Fluxos</Title >
        </Col>
        <Col span={10} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="primary" onClick={createFlow} icon={<PlusOutlined />} loading={isLoading} disabled={!hasPermission(500)}>
            Novo fluxo
          </Button>
        </Col>
      </Row>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={columns}
          dataSource={dataTable}
          rowKey={(record) => record.id}
          loading={isLoading}
          size="small"
          pagination={false}
        />
        <Pagination
          pageSize={limiteLinhas}
          style={{ float: 'right', margin: '5px' }}
          current={currentPage}
          onChange={onChangePage}
          total={totalCount}
          showTotal={total => `Total ${total} registro(s)`}
        />
      </ConfigProvider>
    </>
  );
};

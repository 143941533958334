import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  notification
} from 'antd';
import {
  PlusOutlined,
  FormOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { ISigningRoles, SigningRolesService } from '../../shared/services/api';

const { Title } = Typography;

export const SigningRoles = () => {

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<ISigningRoles[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limiteLinhas, setLimiteLinhas] = useState(10);

  const getData = useCallback(() => {
    setIsLoading(true);

    const limiteLinhas: number = parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS || '', 10);
    setLimiteLinhas(limiteLinhas);

    SigningRolesService.getAll(currentPage, limiteLinhas)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setDataTable(result.data);
          setTotalCount(result.total);
        }
      });
  }, [currentPage]);

  const openModal = () => {
    form.resetFields();
    setIsModalOpen(true);
    setModalTitle('Nova função');
  };

  const handleSave = async (data: ISigningRoles) => {
    if (data.id) {
      setIsLoading(true);
      SigningRolesService
        .update(data.id, data)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            notification.success({
              message: 'Função atualizada com sucesso!'
            });
            setIsModalOpen(false);
            getData();
          }
        });
    } else {
      setIsLoading(true);
      SigningRolesService
        .create(data)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            notification.success({
              message: 'Função criada com sucesso!'
            });
            setIsModalOpen(false);
            getData();
          }
        });
    }
  };

  const handleRemove = async (id: number) => {
    setIsLoading(true);
    SigningRolesService
      .exclude(id)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          notification.info({
            message: 'Função excluida com sucesso!'
          });
          setIsModalOpen(false);
          getData();
        }
      });
  };

  const hidrateFormWithValues = async (data: ISigningRoles) => {
    form.setFieldsValue(data);
    setIsModalOpen(true);
    setModalTitle('Editar função');
  };

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnsType<ISigningRoles> = [
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      key: 'actions',
      render: (record: ISigningRoles) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<FormOutlined />} onClick={() => hidrateFormWithValues(record)} />
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir este função?"
            onConfirm={() => handleRemove(record.id)}
            okText="Sim"
            cancelText="Não"
            placement="left"
          >
            <Button type="text" shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    }
  ];

  const customizeRenderEmpty = () => (
    <Empty
      imageStyle={{ height: 60 }}
      description={
        <span>
          Nenhum registro encontrado.
        </span>
      }
    >
      <Button type="primary" icon={<PlusOutlined />} onClick={openModal}>
        Nova função
      </Button>
    </Empty>
  );

  return (
    <>
      <Row>
        <Col span={20}>
          <Title level={3}>Funções de assinatura</Title >
        </Col>
        <Col span={4} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="primary" onClick={openModal} icon={<PlusOutlined />} loading={isLoading}>
            Nova função
          </Button>
        </Col>
      </Row>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={columns}
          dataSource={dataTable}
          rowKey={(record) => record.id}
          loading={isLoading}
          size="small"
          pagination={false}
        />
        <Pagination
          pageSize={limiteLinhas}
          style={{ float: 'right', margin: '5px' }}
          current={currentPage}
          onChange={onChangePage}
          total={totalCount}
          showTotal={total => `Total ${total} registro(s)`}
        />
      </ConfigProvider>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        footer={[
        ]}
      >
        <Title level={3}>{modalTitle}</Title>
        <Form
          layout="vertical"
          form={form}
          hideRequiredMark
          onFinish={handleSave}
          initialValues={{
            id: ''
          }}
        >
          <Row>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Descrição"
                rules={[{ required: true, message: 'Descrição é um campo obrigatório' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button onClick={() => setIsModalOpen(false)} style={{ marginRight: 8 }} htmlType="button">
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Salvar alterações
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
